import { i18n } from 'src/i18n';

const roles = {
  admin: 'admin',
  finance: 'finance',
  student: 'student',
  reviewer: 'reviewer',
  teacher: 'teacher',
  unregistered: 'unregistered',
} as const;

export type RolesType = keyof typeof roles;

class Roles {
  static get values() {
    return roles;
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }
}

export default Roles;
