import { conversationModeMap } from './chat';

export const notificationModeMap = {
  ...conversationModeMap,
} as const;
export const notificationModeValues = Object.freeze([...Object.values(notificationModeMap)] as const);
export type NotificationModeType = (typeof notificationModeValues)[number];

export interface Notification {
  count: number;
  courseId?: string;
  courseName: string;
  createdAt: Date;
  id: string;
  isTeacher: boolean;
  mode: NotificationModeType;
  creator?: string;
}
