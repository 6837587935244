import Iconify from 'src/view/shared/Iconify';
import { ListItemText, ListItemIcon, Box } from '@mui/material';
import styles, { iconStyle } from './NavItemContent.style';

type Props = {
  label: string;
  icon: React.ReactNode;
  isCollapse?: boolean;
  open: boolean;
  active?: boolean;
};

const NavItemContent: React.FC<Props> = (props) => {
  const { label, icon, children, isCollapse, open, active = false } = props;

  return (
    <Box sx={styles.container}>
      {icon && <ListItemIcon sx={[styles.listItemIcon, active && styles.listItemIconActive]}>{icon}</ListItemIcon>}
      {!isCollapse && (
        <ListItemText
          disableTypography
          primary={label}
          sx={[
            styles.listItemText,
            active && styles.listItemTextActive,
            isCollapse ? styles.listItemTextCollapsed : {},
          ]}
        />
      )}
      {!isCollapse && children && (
        <Iconify icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={iconStyle.icon} />
      )}
    </Box>
  );
};

export default NavItemContent;
