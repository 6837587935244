import Plans from 'src/security/plans';
import Roles from 'src/security/roles';

const plans = Plans.values;

export default class PermissionChecker {
  currentUser;

  constructor(currentUser) {
    this.currentUser = currentUser;
  }

  get currentUserRolesIds() {
    if (!this.currentUser) {
      return [];
    }

    return this.currentUser.roles;
  }

  match(permission) {
    if (!permission) {
      return true;
    }

    if (!this.planMatchOneOf(permission.allowedPlans)) {
      return false;
    }

    if (this.rolesMatchOneOf(permission?.forbiddenRoles)) {
      return false;
    }

    return this.rolesMatchOneOf(permission.allowedRoles);
  }

  lockedForCurrentPlan(permission) {
    if (!permission) {
      return false;
    }

    if (!this.rolesMatchOneOf(permission.allowedRoles)) {
      return false;
    }

    return !this.planMatchOneOf(permission.allowedPlans);
  }

  rolesMatchOneOf(arg: any[]) {
    if (!this.currentUserRolesIds) {
      return false;
    }

    if (!arg) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      if (arg.some((role) => role === Roles.values.unregistered) && this.currentUserRolesIds?.length === 0) {
        return true;
      }

      return arg.some((role) => this.currentUserRolesIds.includes(role));
    }

    return this.currentUserRolesIds.includes(arg);
  }

  planMatchOneOf(arg) {
    if (!this.currentTenantPlan) {
      return false;
    }

    if (!arg) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      return arg.some((plan) => plan === this.currentTenantPlan);
    }

    return arg === this.currentTenantPlan;
  }

  get currentTenantPlan() {
    return plans.free;
  }

  get isEmptyPermissions() {
    if (!this.isAuthenticated) {
      return true;
    }

    if (this.currentUser.status !== 'active') {
      return true;
    }

    return !this.currentUser.roles || !this.currentUser.roles.length;
  }

  get isAuthenticated() {
    return Boolean(this.currentUser) && Boolean(this.currentUser.id);
  }

  get isEmailVerified() {
    if (!this.isAuthenticated) {
      return false;
    }

    return this.currentUser.emailVerified;
  }
}
