import { AppBar, Container } from '@mui/material';
import HeaderToolbar from 'src/view/layout/header/HeaderToolbar/HeaderToolbar';
import styles from './HeaderSimple.styles';
import KnowmadersUniversityLogo from 'src/view/shared/logos/KnowmadersUniversityLogo';
import useResponsive from 'src/hooks/useResponsive';
import KnowmadersUniversityLogoSmall from 'src/view/shared/logos/KnowmadersUniversityLogoSmall';

interface Props {}

const SimpleHeader: React.FC<Props> = (_props) => {
  const isDesktop = useResponsive('up', 'md');
  return (
    <AppBar sx={styles.app}>
      <HeaderToolbar disableGutters sx={styles.toolbar}>
        <Container maxWidth={false} sx={styles.container}>
          {isDesktop ? <KnowmadersUniversityLogo sx={styles.logoSize} /> : <KnowmadersUniversityLogoSmall />}
        </Container>
      </HeaderToolbar>
    </AppBar>
  );
};

export default SimpleHeader;
