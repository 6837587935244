import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  link: {
    lineHeight: 0,
    cursor: 'pointer',
    display: 'inline-flex',

    '& svg': {
      width: '100%',
      height: 'auto',
    },

    '& path': {
      fill: (theme) => theme.palette.primary.main,
    },
  },
};

export default styles;
