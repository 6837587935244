import authAxios from 'src/modules/shared/axios/authAxios';

export default class ChatService {
  static async markConversationAsRead(conversationId) {
    const response = await authAxios.patch('/conversation', { op: 'markAsRead', value: conversationId });

    return response.data;
  }

  static async listConversations() {
    const response = await authAxios.get(`/conversation`);

    return response.data;
  }

  static async getConversation(conversationId) {
    const params = {};

    const response = await authAxios.get(`/conversation/${conversationId}`, { params });

    return response.data;
  }

  static async sendMessageToNewConversation(message, to) {
    const body = { message, to };
    const response = await authAxios.post(`/conversation`, body);

    return response.data;
  }

  static async sendMessage(message, conversationId) {
    const body = { message };

    const response = await authAxios.post(`/conversation/${conversationId}/message`, body);

    return response.data;
  }

  static async fetchNewMessages(conversationId, lastMessageId, createdAt) {
    const params = { lastMessageId, createdAt };

    const response = await authAxios.get(`/conversation/${conversationId}/message`, { params });

    return response.data;
  }
}
