import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  navLink: {
    height: 48,
    textTransform: 'uppercase',
    paddingLeft: (theme) => theme.spacing(2.5),
    paddingRight: (theme) => theme.spacing(2.5),
    fontWeight: 500,
    color: (theme) => theme.palette.primary.main,
  },
  active: {
    color: (theme) => theme.palette.primary.main,
    fontWeight: 700,
  },
};

export default styles;
