import { combineReducers } from 'redux';
import destroy from 'src/modules/meetUp/destroy/meetUpDestroyReducers';
import form from 'src/modules/meetUp/form/meetUpFormReducers';
import list from 'src/modules/meetUp/list/meetUpListReducers';
import publicList from 'src/modules/meetUp/publicList/meetUpPublicListReducers';
import view from 'src/modules/meetUp/view/meetUpViewReducers';
import publicView from 'src/modules/meetUp/publicView/meetUpPublicViewReducers';

export default combineReducers({
  list,
  publicList,
  form,
  view,
  publicView,
  destroy,
});
