import { Box, Link as MaterialLink, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import styles from './Copyright.styles';

interface Props {}

const CopyRight: React.FC<Props> = (_props) => {
  return (
    <Stack sx={styles.wrapper}>
      <Typography sx={styles.font} variant="h6">
        {i18n('components.copyRight.copyright')}
      </Typography>
      <Box sx={styles.wrapperLinks}>
        <MaterialLink sx={styles.font} component={Link} to="/help-center" variant="h6">
          {i18n('components.copyRight.help')}
        </MaterialLink>
        <MaterialLink sx={styles.font} component={Link} to="/terms-of-service" variant="h6">
          {i18n('components.copyRight.terms')}
        </MaterialLink>
      </Box>
    </Stack>
  );
};

export default CopyRight;
