import { createSelector } from 'reselect';
import { UserRolesType } from 'src/@types/user';

const selectRaw = (state) => state.auth;

const selectAuthenticationUser = createSelector([selectRaw], (auth) => auth.authenticationUser);

const selectCurrentUser = createSelector([selectRaw], (auth) => auth.currentUser);

const selectCurrentUserEmail = createSelector([selectCurrentUser], (currentUser) =>
  currentUser ? currentUser.email : null,
);

const selectCurrentUserFullName = createSelector([selectCurrentUser], (currentUser) =>
  currentUser ? currentUser.fullName : '',
);
const selectCurrentUserId = createSelector([selectCurrentUser], (currentUser): string => currentUser?.id);

const selectSignedIn = createSelector(
  [selectCurrentUser],
  (currentUser) => Boolean(currentUser) && Boolean(currentUser.id),
);

const selectRoles = createSelector([selectCurrentUser], (currentUser): UserRolesType[] => {
  if (!currentUser) {
    return [];
  }

  return currentUser.roles;
});

const selectLoading = createSelector([selectRaw], (auth) => Boolean(auth.loading));

const selectLoadingInit = createSelector([selectRaw], (auth) => Boolean(auth.loadingInit));

const selectLoadingEmailConfirmation = createSelector([selectRaw], (auth) => Boolean(auth.loadingEmailConfirmation));

const selectLoadingPasswordResetEmail = createSelector([selectRaw], (auth) => Boolean(auth.loadingPasswordResetEmail));

const selectLoadingPasswordReset = createSelector([selectRaw], (auth) => Boolean(auth.loadingPasswordReset));

const selectLoadingVerifyEmail = createSelector([selectRaw], (auth) => Boolean(auth.loadingVerifyEmail));

const selectLoadingPasswordChange = createSelector([selectRaw], (auth) => Boolean(auth.loadingPasswordChange));

const selectLoadingUpdateProfile = createSelector([selectRaw], (auth) => Boolean(auth.loadingUpdateProfile));

const selectLoadingUpdateTeacher = createSelector([selectRaw], (auth) => Boolean(auth.loadingUpdateTeacher));

const selectErrorMessage = createSelector([selectRaw], (auth) => auth.errorMessage);

const selectErrorMessageVerifyEmail = createSelector([selectRaw], (auth) => auth.errorMessageVerifyEmail);
const selectCurrentUserNameOrEmailPrefix = createSelector(
  [selectCurrentUser, selectCurrentUserFullName],
  (currentUser, fullName) => {
    if (!currentUser) {
      return '';
    }

    if (fullName && fullName.length < 25) {
      return fullName;
    }

    if (currentUser.firstName) {
      return currentUser.firstName;
    }

    return currentUser.email.split('@')[0];
  },
);

const selectCurrentUserAvatar = createSelector([selectCurrentUser], (currentUser) => {
  if (!currentUser || !currentUser.avatar || !currentUser.avatar.downloadUrl) {
    return null;
  }

  return currentUser.avatar.downloadUrl;
});

const authSelectors = {
  selectLoadingPasswordResetEmail,
  selectLoadingEmailConfirmation,
  selectLoadingInit,
  selectLoadingUpdateProfile,
  selectLoadingUpdateTeacher,
  selectLoading,
  selectRoles,
  selectSignedIn,
  selectCurrentUserFullName,
  selectCurrentUserEmail,
  selectCurrentUser,
  selectAuthenticationUser,
  selectErrorMessage,
  selectErrorMessageVerifyEmail,
  selectRaw,
  selectCurrentUserNameOrEmailPrefix,
  selectCurrentUserAvatar,
  selectLoadingPasswordReset,
  selectLoadingVerifyEmail,
  selectLoadingPasswordChange,
  selectCurrentUserId,
};

export default authSelectors;
