import { memo } from 'react';
import { BoxProps } from '@mui/material';
import styles from './KnowmadersLogo.style';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  onDark?: boolean;
  isSimple?: boolean;
}

function KnowmadersLogo({ onDark = false, isSimple = false, sx }: LogoProps) {
  return (
    <Box
      sx={{
        ...styles.logo,
        ...sx,
      }}
    >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 470.1 134.89">
        <path
          d="M454.31,71.74c-1.71-3.09-3.87-5.58-6.46-7.49c-2.59-1.9-5.37-3.45-8.35-4.63c-2.97-1.18-5.76-2.34-8.35-3.49
	c-2.59-1.14-4.75-2.5-6.46-4.06c-1.72-1.56-2.57-3.68-2.57-6.34c0-1.83,0.53-3.49,1.6-4.97c1.07-1.49,2.74-2.23,5.03-2.23
	c2.06,0,3.58,0.86,4.57,2.57c0.99,1.71,1.48,3.79,1.48,6.23v1.94h19.9c0-8.76-2.08-15.15-6.23-19.15c-4.15-4-10.88-6-20.18-6
	c-9,0-15.7,2.17-20.12,6.52c-1.38,1.35-2.52,2.92-3.46,4.66c-0.4-0.44-0.8-0.89-1.24-1.3c-2.33-2.19-5.14-3.93-8.36-5.18
	c-3.19-1.23-6.77-1.85-10.63-1.85H351.9V9.66h-20.58v17.32v25.5h-0.23c-1.07-3.2-2.76-5.43-5.09-6.69
	c-2.33-1.26-4.94-1.89-7.83-1.89c-2.82,0-5.41,0.53-7.77,1.6s-4.36,2.82-6,5.26c-1.34,2-2.43,4.54-3.29,7.56V26.98h-30.03
	l-12.09,66.89l-2.66-0.03l-11.05-66.85h-21.31h-9.77h-7.65L193.32,79.7l-12.38-52.73h-17.9L151.75,79.7l-13.13-51.81
	c0.03-0.95,0.03-1.88-0.05-2.73c-0.35-3.89-1.49-7.01-3.41-9.38c-1.93-2.36-4.52-3.99-7.79-4.9c-15.78-2.52-21.9,4.21-30.14,15.32
	h-0.42l4.17-13.76h-24.8L56.17,78.47L48.5,62.65l19.83-35.67H46.52L31.32,58.31h-0.22V26.98H13.22v81.64h17.88V87l4.3-6.86
	l11.79,27.95l-5.2,17.14h26.05L90.6,50.81c1.43-4.73,3.16-8.13,5.18-10.22c2.02-2.09,4.7-3.13,8.04-3.13c3.34,0,5.38,1.04,6.14,3.13
	c0.76,2.08,0.42,5.49-1.01,10.22l-22.56,74.42h24.14l19.41-64.01l13.36,47.18h15.94l12.88-52.97l12.63,52.97h16.43l13.04-46.56
	v46.77h19.89V47.79h0.23l11.32,60.83h24.01l11.32-60.83h0.23v60.83h19.89v-14.3c0.6,2.36,1.32,4.44,2.2,6.18
	c2.74,5.41,7.55,8.12,14.41,8.12c3.66,0,6.59-0.76,8.8-2.29c1.97-1.36,3.56-3.52,4.8-6.4v8.69h21.45V78.99h25.54
	c5.12,0,6.83,1.07,7.32,1.53c1.02,0.95,1.56,2.89,1.56,5.61v22.48h21.57v-4.12c2.11,1.39,4.59,2.42,7.48,3.03
	c3.39,0.72,7.3,1.09,11.72,1.09c9.38,0,16.54-1.96,21.5-5.89c4.95-3.93,7.43-10.23,7.43-18.92
	C456.89,78.85,456.03,74.83,454.31,71.74z M331.09,84.72c-0.15,2.21-0.44,3.98-0.86,5.32c-0.42,1.34-1.01,2.27-1.77,2.8
	c-0.76,0.53-1.72,0.8-2.86,0.8c-1.22,0-2.21-0.27-2.97-0.8c-0.76-0.53-1.35-1.47-1.77-2.8c-0.42-1.33-0.71-3.1-0.86-5.32
	c-0.15-2.21-0.23-4.95-0.23-8.23c0-3.35,0.07-6.14,0.23-8.35c0.15-2.21,0.44-3.98,0.86-5.32c0.42-1.33,1.01-2.27,1.77-2.8
	c0.76-0.53,1.75-0.8,2.97-0.8c1.14,0,2.1,0.27,2.86,0.8c0.76,0.53,1.35,1.47,1.77,2.8c0.42,1.33,0.71,3.11,0.86,5.32
	c0.15,2.21,0.23,4.99,0.23,8.35C331.32,79.76,331.24,82.51,331.09,84.72z M388.1,58.36c-1.14,1.23-3.49,1.88-6.82,1.88h-28.51V46.07
	h29.08c3.06,0,5.17,0.62,6.28,1.86c1.21,1.34,1.8,3.07,1.8,5.29C389.94,55.34,389.34,57.02,388.1,58.36z M433.16,92.26
	c-1.18,1.53-2.88,2.29-5.09,2.29c-2.06,0-3.74-0.71-5.03-2.12c-1.3-1.41-1.95-3.87-1.95-7.38v-1.94h-12.36
	c-0.12-4.49-0.95-7.92-2.56-10.44c-0.82-1.29-1.89-2.42-3.2-3.39c1.46-1.12,2.72-2.46,3.8-3.98c1.03,1.2,2.16,2.26,3.4,3.18
	c2.48,1.83,5.16,3.32,8.06,4.46c2.9,1.14,5.58,2.27,8.06,3.37c2.48,1.11,4.53,2.44,6.17,4c1.64,1.56,2.46,3.68,2.46,6.34
	C434.93,88.87,434.34,90.74,433.16,92.26z"
        />
      </svg>
    </Box>
  );
}

export default memo(KnowmadersLogo);
