import ImporterSchema from 'src/modules/shared/importer/importerSchema';

export default class Importer {
  schema: ImporterSchema;

  constructor(fields) {
    this.schema = new ImporterSchema(fields);
  }

  async castForDisplay(row, index) {
    return this.schema.castForDisplay(row, index);
  }

  async castForImport(row) {
    return this.schema.castForImport(row);
  }
}
