import categoryMapSelectors from 'src/modules/category/map/categoryMapSelectors';
import CoffeeAndBeersService from 'src/modules/coffeeAndBeers/coffeeAndBeersService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'COFFEE_AND_BEERS_SUMMARY';

const coffeeAndBeersSummaryActions = {
  GET_CATEGORY_SUMMARY_PENDING: `${prefix}_GET_CATEGORY_SUMMARY_PENDING`,
  GET_CATEGORY_SUMMARY_FULFILLED: `${prefix}_GET_CATEGORY_SUMMARY_FULFILLED`,
  GET_CATEGORY_SUMMARY_REJECTED: `${prefix}_GET_CATEGORY_SUMMARY_REJECTED`,
  CLEAN: `${prefix}_CLEAN`,
  ADD_SUBCATEGORY_PARTICIPANT: `${prefix}_ADD_SUBCATEGORY_PARTICIPANT`,
  DELETE_SUBCATEGORY_PARTICIPANT: `${prefix}_DELETE_SUBCATEGORY_PARTICIPANT`,
  DELETE_SUBCATEGORY_TABLE: `${prefix}_DELETE_SUBCATEGORY_TABLE`,

  getSummaryFromTopCategory: (categoryId) => async (dispatch, getState) => {
    try {
      dispatch({ type: coffeeAndBeersSummaryActions.GET_CATEGORY_SUMMARY_PENDING });

      const response = await CoffeeAndBeersService.getSummaryByCategory(categoryId);
      const map = categoryMapSelectors.selectMap(getState());
      const subcategories = categoryMapSelectors.selectSubcategories(getState());
      const subcategoriesList = subcategories.filter(
        (subcategory: string) => categoryId === map?.[subcategory]?.parent,
      );

      dispatch({
        type: coffeeAndBeersSummaryActions.GET_CATEGORY_SUMMARY_FULFILLED,
        payload: {
          subcategoriesList: subcategoriesList,
          data: response,
        },
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({ type: coffeeAndBeersSummaryActions.GET_CATEGORY_SUMMARY_REJECTED });
    }
  },
  cleanSummaryByCategory: () => async (dispatch) => {
    dispatch({ type: coffeeAndBeersSummaryActions.CLEAN });
  },
  addSubcategoryParticipant:
    ({ userId, userFullName, userAvatar, tableId, subcategoryId, tableName, drink }) =>
    async (dispatch) => {
      dispatch({
        type: coffeeAndBeersSummaryActions.ADD_SUBCATEGORY_PARTICIPANT,
        payload: {
          userId,
          userFullName,
          userAvatar,
          tableId,
          subcategoryId,
          tableName,
          drink,
        },
      });
    },
  deleteSubcategoryParticipant:
    ({ subcategoryId, userId, tableId }) =>
    async (dispatch) => {
      dispatch({
        type: coffeeAndBeersSummaryActions.DELETE_SUBCATEGORY_PARTICIPANT,
        payload: {
          subcategoryId,
          userId,
          tableId,
        },
      });
    },
  deleteSubcategoryTable:
    ({ tableId, subcategoryId }) =>
    async (dispatch) => {
      dispatch({
        type: coffeeAndBeersSummaryActions.DELETE_SUBCATEGORY_TABLE,
        payload: {
          tableId,
          subcategoryId,
        },
      });
    },
};

export default coffeeAndBeersSummaryActions;
