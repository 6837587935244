import { createSelector } from 'reselect';

const selectRaw = (state) => state.chat.currentConversation;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);
const selectActiveConversationId = createSelector([selectRaw], (raw) => raw.activeConversationId);
const selectConversation = createSelector([selectRaw], (raw) => raw.conversation);
const selectStatus = createSelector([selectRaw], (raw) => raw.status);

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectCount], (count) => count > 0);

const chatCurrentConversationSelectors = {
  selectLoading,
  selectRows,
  selectActiveConversationId,
  selectConversation,
  selectCount,
  selectHasRows,
  selectStatus,
};

export default chatCurrentConversationSelectors;
