import authAxios from 'src/modules/shared/axios/authAxios';

export default class CoffeeAndBeersService {
  static async listPublic(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/category`, {
      params,
    });

    return response.data;
  }

  static async getTotalSummary(categoryIds?) {
    const params = {
      categoryIds,
    };

    const response = await authAxios.get(`/table/total/summary`, {
      params,
    });

    return response.data;
  }
  static async getSummaryByCategory(categoryId) {
    const params = {
      limit: 6,
    };

    const response = await authAxios.get(`table/summary/${categoryId}`, {
      params,
    });

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/knowmader/${id}`);

    return response.data;
  }
}
