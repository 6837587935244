import { ComponentStyle, IconifyStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  mainIcon: {
    // transform: 'scale(0.5)',
    // transition: (theme) =>
    //   theme.transitions.create('transform', {
    //     duration: theme.transitions.duration.shorter,
    //   }),
    mr: 1,
  },
  mainIconActive: {
    // transform: 'scale(1)',
    color: 'primary.main',
    mr: 1,
  },
};

export const iconifyStyles: IconifyStyle = {
  icon: {
    width: 16,
    height: 16,
    ml: 1,
  },
};

export default styles;
