import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  componentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 4,
    my: 6,
  },
  logoWrapper: {
    justifySelf: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 3,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  whiteIcon: {
    color: (theme) => theme.palette.common.white,
  },
  title: {
    fontWeight: 700,
    fontFamily: 'FF Mark',
    color: (theme) => theme.palette.text.primary,
  },
  subtitle: {
    fontWeight: 600,
    fontFamily: 'FF Mark',
    color: (theme) => theme.palette.text.primary,
    textAlign: 'center',
  },
};

export default styles;
