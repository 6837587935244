import { Box, Toolbar, ToolbarProps } from '@mui/material';
import { Style } from 'src/@types/Style';
import styles from './HeaderToolbar.style';
import { HEADER_DESKTOP_HEIGHT } from 'src/config';
import useOffSetTop from 'src/hooks/useOffSetTop';

interface Props extends Omit<ToolbarProps, 'sx'> {
  sx?: Style;
}

const HeaderToolbar: React.FC<Props> = (props) => {
  const { sx = {}, ...otherProps } = props;
  const isScrolling = useOffSetTop(HEADER_DESKTOP_HEIGHT);

  return (
    <>
      <Toolbar {...otherProps} sx={[styles.toolbar, isScrolling && styles.scrolling, sx]} />
      {isScrolling && <Box sx={styles.shadow} />}
    </>
  );
};

export default HeaderToolbar;
