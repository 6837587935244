import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  column: {
    pb: { md: 1 },
    alignItems: { xs: 'center', md: 'flex-start' },
  },
  linkHeader: {
    fontFamily: 'FF Mark',
    fontWeight: 900,
  },
  link: {
    fontFamily: 'FF Mark',
    fontWeight: 900,
    mt: 1,
  },
};

export default styles;
