import PaymentGroupService from 'src/modules/paymentGroup/paymentGroupService';
import selectors from 'src/modules/paymentGroup/list/paymentGroupListSelectors';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'PAYMENT_GROUP_LIST';

const paymentGroupListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  doReset: () => async (dispatch) => {
    dispatch({
      type: paymentGroupListActions.RESETED,
    });

    dispatch(paymentGroupListActions.doFetch());
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: paymentGroupListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(paymentGroupListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: paymentGroupListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(paymentGroupListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(paymentGroupListActions.doFetch(filter, rawFilter, true));
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: paymentGroupListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });

        const response = await PaymentGroupService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: paymentGroupListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: paymentGroupListActions.FETCH_ERROR,
        });
      }
    },
};

export default paymentGroupListActions;
