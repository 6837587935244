import { Box, Drawer } from '@mui/material';
import styles from './NavbarVertical.styles';
import NavbarContent from './NavbarContent';
import useResponsive from 'src/hooks/useResponsive';
import { MenuType } from 'src/view/menus';

type Props = {
  isOpenSidebarMobile: boolean;
  menu: MenuType[];
  isCollapse: boolean;
  isSidebarHover: boolean;
  closeSidebarMobile: VoidFunction;
  toggleSidebarDesktop: VoidFunction;
  onHoverEnter: VoidFunction;
  onHoverLeave: VoidFunction;
};

const NavbarVertical: React.FC<Props> = (props) => {
  const {
    isOpenSidebarMobile,
    menu,
    isCollapse,
    isSidebarHover,
    closeSidebarMobile,
    toggleSidebarDesktop,
    onHoverEnter,
    onHoverLeave,
  } = props;
  const isDesktop = useResponsive('up', 'lg');

  const onToggleCollapse = () => {
    toggleSidebarDesktop();
    onHoverLeave();
  };

  return (
    <Box
      sx={[
        styles.container,
        isCollapse && !isSidebarHover ? styles.containerCollapse : {},
        isCollapse && isSidebarHover ? styles.collapseClick : {},
      ]}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: [
              styles.paperDesktopDrawer,
              isCollapse && !isSidebarHover ? styles.paperDesktopDrawerCollapse : {},
              isCollapse && isSidebarHover ? styles.paperDesktopDrawerCollapseHover : {},
            ],
          }}
        >
          <NavbarContent
            isCollapse={(isCollapse && !isSidebarHover) ?? false}
            collapseClick={isCollapse}
            onToggleCollapse={onToggleCollapse}
            menu={menu}
          />
        </Drawer>
      ) : (
        <Drawer open={isOpenSidebarMobile} onClose={closeSidebarMobile} PaperProps={{ sx: styles.paperMobileDrawer }}>
          <NavbarContent
            isCollapse={(isCollapse && !isSidebarHover) ?? false}
            onToggleCollapse={onToggleCollapse}
            collapseClick={isCollapse && !isSidebarHover}
            menu={menu}
          />
        </Drawer>
      )}
    </Box>
  );
};

export default NavbarVertical;
