import { SocialLinks } from './socials';

// ----------------------------------------------------------------------

export type CourseTeacherProp = {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  role: string;
  ratings: number;
  avatar: string;
  courses: number;
  reviews: { score: number; count: number };
  totalCourses: number;
  students: number;
};

export type CourseModuleProp = {
  _id: string;
  title: string;
  description: string;
  events: CourseEventProp[];
  isUnLock: boolean;
};

export type CourseEventProp = {
  activityResults: ActivityResultProp[];
  _id: string;
  title: string;
  description: string;
  documents: any[];
  documentsCount: number;
  startDate: Date;
  endDate: Date;
  type: EventTypeType;
  status?: 'corrected' | 'done' | 'pending';
};

export type CourseEventExamAnswerProp = {
  _id: string;
  answer: string;
  correct: boolean;
};

export type CourseEventExamQuestionProp = {
  _id: string;
  question: string;
  answers: {
    name: string;
    description: string;
    questions: CourseEventExamAnswerProp[];
  };
};

export interface ExamResult {
  _id: string;
  student: string;
  score: number;
}

export type CourseEventExamProp = {
  _id: string;
  exam: {
    _id: string;
    name: string;
    description: string;
    questions: CourseEventExamQuestionProp[];
    score: number | undefined;
  };
  examResults?: ExamResult[];
  startDate: Date;
  duration: number | undefined;
  endDate: Date;
  type: 'exam';
};

export type ActivityResultProp = {
  _id: string;
  documents: any[];
  comments: string;
  student?: string | UserType;
  score?: number;
  teacherComments?: string;
};

export type CourseByCategoryProps = {
  id: string;
  name: string;
  students: number;
};

export const eventTypeMap = {
  session: 'session',
  activity: 'activity',
  exam: 'exam',
};
export const eventTypeValues = Object.freeze([...Object.values(eventTypeMap)] as const);
export type EventTypeType = typeof eventTypeValues[number];

export const CourseLanguage = {
  es: 'es',
  gl: 'gl',
  eu: 'eu',
  ca: 'ca',
  en: 'en',
} as const;
export const courseLanguageValues = Object.freeze([...Object.values(CourseLanguage)] as const);
export type CourseLanguageType = typeof courseLanguageValues[number];

export const CourseLevel = {
  beginner: 'beginner',
  intermediate: 'intermediate',
  expert: 'expert',
} as const;
export const courseLevelValues = Object.freeze([...Object.values(CourseLevel)] as const);
export type CourseLevelType = typeof courseLevelValues[number];

export const CourseStatus = {
  confirmed: 'confirmed',
  draft: 'draft',
  readyToPublish: 'readyToPublish',
  published: 'published',
  active: 'active',
  activeValidated: 'activeValidated',
  finished: 'finished',
  cancelled: 'cancelled',
} as const;
export const courseStatusValues = Object.freeze([...Object.values(CourseStatus)] as const);
export type CourseStatusType = typeof courseStatusValues[number];

export const CourseSubscriptionStatus = {
  inscription: 'inscription',
  closed: 'closed',
} as const;
const courseSubscriptionValues = Object.freeze([...Object.values(CourseSubscriptionStatus)] as const);
export type CourseSubscriptionStatusType = typeof courseSubscriptionValues[number];

export type UserType = {
  id: string;
  firstName: string;
  fullName: string;
  avatar: string;
};

export interface CourseBasicProps {
  id: string;
  name: string;
  category?: string;
  description: string;
  firstEventTime: string;
  image: string | { downloadUrl: string };
  inscriptedsTotal: number;
  maxStudents: number;
  price: number;
  status?: CourseStatusType;
  teacher: {
    firstName: string;
    fullName: string;
    avatar: string;
  };
  earlyBirdDiscount?: EarlyBirdDiscountType;
}

export type EarlyBirdDiscountType = {
  discount: number;
  endDate: Date;
};

export interface CourseProps extends CourseBasicProps {
  slug: string;
  price: number;
  minStudents: number;
  level: string;
  ratings: number;
  reviews: number;
  students: UserType[];
  priceSale: number;
  resources: number;
  shareLinks: SocialLinks;
  modules: CourseModuleProp[];
  teacher: CourseTeacherProp;
  createdAt: Date | string | number;
  whatWillYouLearn: string;
  whoIsThisCourseFor: string;
  requirements: string;
  presentationVideo: string;
  content: string;
  documentation: string;
  language: string;
  inscripteds?: string[];
  meInscripted?: boolean;
  meFirstCheckpoint?: {
    continue: boolean;
    createdAt: Date;
    comments?: string;
  };
  myFinalResult?: {
    attendance: boolean;
    score: number;
  };
  firstCheckpointProcessed?: boolean;
  earlyBirdDiscounts?: EarlyBirdDiscountType[];
}

export function isUserType(obj: any): obj is UserType {
  return 'id' in obj;
}
