import { Box, Typography } from '@mui/material';
import { i18n } from 'src/i18n';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import styles from './LoggedSwitch.styles';

interface Props {}

const LoggedSwitch: React.FC<Props> = (_props) => {
  const currentUser = useSelector(authSelectors.selectCurrentUser);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={[styles.circleWrapper, currentUser ? styles.on : styles.off]}>
        <Box sx={styles.circle} />
      </Box>
      <Typography variant="h6" sx={styles.font}>
        {currentUser ? i18n('components.footer.on') : i18n('components.footer.off')}
      </Typography>
    </Box>
  );
};

export default LoggedSwitch;
