import Plans, { PlansType } from 'src/security/plans';
import Roles, { RolesType } from 'src/security/roles';
import Storage, { StorageType } from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

export interface PermissionType {
  id: string;
  allowedRoles: RolesType[];
  allowedPlans: PlansType[];
  allowedStorage?: StorageType[];
  forbiddenRoles?: RolesType[];
}

class Permissions {
  static get values() {
    return {
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.admin, roles.reviewer, roles.teacher],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      teacherRequestRead: {
        id: 'teacherRequestRead',
        allowedRoles: [roles.admin, roles.reviewer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userRateImport: {
        id: 'userRateImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userRateCreate: {
        id: 'userRateCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      userRateEdit: {
        id: 'userRateEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      userRateDestroy: {
        id: 'userRateDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      userRateRead: {
        id: 'userRateRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userRateAutocomplete: {
        id: 'userRateAutocomplete',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      categoryCreate: {
        id: 'categoryCreate',
        allowedRoles: [roles.admin, roles.reviewer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.categoryImage],
      },
      categoryEdit: {
        id: 'categoryEdit',
        allowedRoles: [roles.admin, roles.reviewer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.categoryImage],
      },
      categoryDestroy: {
        id: 'categoryDestroy',
        allowedRoles: [roles.admin, roles.reviewer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.categoryImage],
      },

      courseImport: {
        id: 'courseImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      courseCreate: {
        id: 'courseCreate',
        allowedRoles: [roles.admin, roles.teacher],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.courseImage],
      },
      courseEdit: {
        id: 'courseEdit',
        allowedRoles: [roles.admin, roles.teacher],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.courseImage],
      },
      courseDestroy: {
        id: 'courseDestroy',
        allowedRoles: [roles.admin, roles.teacher],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.courseImage],
      },
      courseRead: {
        id: 'courseRead',
        allowedRoles: [roles.admin, roles.teacher, roles.student],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      courseAutocomplete: {
        id: 'courseAutocomplete',
        allowedRoles: [roles.admin, roles.teacher, roles.student],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      teacherZone: {
        id: 'teacherZone',
        allowedRoles: [roles.teacher],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      myLearning: {
        id: 'myLearning',
        allowedRoles: [roles.student],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      backOfficeZone: {
        id: 'backOfficeZone',
        allowedRoles: [roles.admin, roles.reviewer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      becomeTeacher: {
        id: 'becomeTeacher',
        allowedRoles: [roles.admin, roles.reviewer, roles.student, roles.unregistered],
        forbiddenRoles: [roles.teacher],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      withdrawalRead: {
        id: 'withdrawalRead',
        allowedRoles: [roles.admin, roles.finance],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      admin: {
        id: 'admin',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
