import { TotalSummary } from 'src/@types/totalSummary';
import actions from './coffeeAndBeersTotalSummaryActions';

export type TotalSummaryStateType = {
  loading: boolean;
  error: boolean;
  rows: TotalSummary[];
};

const initialData: TotalSummaryStateType = {
  loading: false,
  error: false,
  rows: [],
};

export default function coffeeAndBeersListReducers(state = initialData, { type, payload }) {
  if (type === actions.CLEAN) {
    return initialData;
  }
  if (type === actions.ADD_CATEGORY_PARTICIPANT) {
    return {
      ...state,
      loading: false,
      error: false,
      rows: state.rows.forEach((categorySummary) => {
        if (categorySummary.category === payload.topCategoryId) {
          return {
            ...categorySummary,
            total: payload.isNewTable ? categorySummary.total + 1 : categorySummary.total,
            totalOpened: categorySummary.totalOpened + 1,
            users: [
              ...categorySummary.users,
              {
                id: payload.userId,
                name: payload.userFullName,
                avatar: payload.userAvatar,
              },
            ],
          };
        } else {
          return categorySummary;
        }
      }),
    };
  }
  if (type === actions.DELETE_CATEGORY_PARTICIPANT) {
    return {
      ...state,
      loading: false,
      error: false,
      rows: state.rows.forEach((categorySummary) => {
        if (categorySummary.category === payload.topCategoryId) {
          return {
            ...categorySummary,
            totalOpened: categorySummary.totalOpened - 1,
            users: categorySummary.users.filter((user) => user.id !== payload.userId),
          };
        } else {
          return categorySummary;
        }
      }),
    };
  }
  if (type === actions.DELETE_CATEGORY_TABLE) {
    return {
      ...state,
      loading: false,
      error: false,
      rows: state.rows.forEach((categorySummary) => {
        if (categorySummary.category === payload.topCategoryId) {
          return {
            ...categorySummary,
            total: categorySummary.total - 1,
            totalOpened: categorySummary.totalOpened - 1,
            users: categorySummary.users.filter((user) => user.id !== payload.userId),
          };
        } else {
          return categorySummary;
        }
      }),
    };
  }
  if (type === actions.GET_TOTAL_SUMMARY_PENDING) {
    return {
      ...state,
      loading: true,
      error: false,
    };
  }
  if (type === actions.GET_TOTAL_SUMMARY_FULFILLED) {
    return {
      ...state,
      loading: false,
      error: false,
      rows: payload.categoriesList.map((categoryId: String) => {
        const data = payload.data?.find((summaryData: TotalSummary) => summaryData.category === categoryId);
        return {
          category: categoryId,
          total: data?.total ?? 0,
          totalOpened: data?.totalOpened ?? 0,
          users: data?.users ?? [],
        };
      }),
    };
  }
  if (type === actions.GET_TOTAL_SUMMARY_REJECTED) {
    return {
      ...state,
      loading: false,
      error: true,
    };
  }

  return state;
}
