import Permissions, { PermissionType } from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import QuizIcon from '@mui/icons-material/Quiz';
import SavingsIcon from '@mui/icons-material/Savings';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import ClassIcon from '@mui/icons-material/Class';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';

const permissions = Permissions.values;

export interface MenuItemType {
  path?: string;
  label: string;
  icon?: React.ReactNode;
  permissionRequired?: PermissionType | null;
  exact?: boolean;
  children?: MenuItemType[];
}

export interface MenuType {
  subheader: string;
  items: MenuItemType[];
}

type MenusType = Record<string, MenuType>;

const MENUS: MenusType = {
  student: {
    subheader: i18n('menu.general'),
    items: [
      {
        path: '/student/my-learning',
        exact: true,
        icon: <CalendarMonthIcon />,
        label: i18n('dashboard.agenda'),
        permissionRequired: permissions.myLearning,
      },
      {
        path: '/student/wallet',
        permissionRequired: permissions.myLearning,
        icon: <LocalAtmIcon />,
        label: i18n('wallet.menu'),
      },
      {
        path: '/student/my-courses',
        permissionRequired: permissions.myLearning,
        icon: <ClassIcon />,
        label: i18n('myLearning.myCourses'),
      },
      {
        path: '/student/my-meet-ups',
        permissionRequired: permissions.myLearning,
        icon: <ClassIcon />,
        label: i18n('myLearning.myMeetUps'),
      },
    ],
  },
  teacher: {
    subheader: i18n('menu.general'),
    items: [
      {
        path: '/teacher-zone',
        exact: true,
        icon: <DashboardIcon />,
        label: i18n('dashboard.menu'),
        permissionRequired: null,
      },
      {
        path: '/teacher/agenda',
        exact: true,
        icon: <CalendarMonthIcon />,
        label: i18n('dashboard.agenda'),
        permissionRequired: permissions.myLearning,
      },
      {
        path: '/teacher/exam',
        permissionRequired: permissions.courseRead,
        icon: <QuizIcon />,
        label: i18n('entities.exam.menu'),
      },

      {
        label: i18n('wallet.menu'),
        permissionRequired: permissions.teacherZone,
        icon: <LocalAtmIcon />,
        children: [
          {
            path: '/teacher/wallet',
            label: i18n('wallet.menu'),
            permissionRequired: permissions.teacherZone,
          },
          {
            path: '/teacher/invoice',
            label: i18n('invoice.menu'),
            permissionRequired: permissions.teacherZone,
          },
        ],
      },

      {
        icon: <ClassIcon />,
        label: i18n('menu.catalog'),
        permissionRequired: permissions.courseRead,
        children: [
          { label: i18n('menu.courses'), path: '/teacher/course' },
          { label: i18n('menu.meetups'), path: '/teacher/meet-up' },
        ],
      },
    ],
  },
  backOffice: {
    subheader: i18n('menu.management'),
    items: [
      {
        path: '/back-office',
        exact: true,
        icon: <DashboardIcon />,
        label: i18n('dashboard.menu'),
        permissionRequired: null,
      },

      {
        path: '/teacher-request',
        label: i18n('teacherRequest.menu'),
        permissionRequired: permissions.teacherRequestRead,
        icon: <MarkunreadMailboxIcon />,
      },
      {
        path: '/category',
        label: i18n('category.menu'),
        permissionRequired: permissions.categoryCreate,
        icon: <BookmarksIcon />,
      },

      {
        label: i18n('withdrawal.menuTop'),
        permissionRequired: permissions.withdrawalRead,
        icon: <SavingsIcon />,
        children: [
          {
            path: '/back-office/withdrawal',
            label: i18n('withdrawal.menu'),
            permissionRequired: permissions.withdrawalRead,
          },
          {
            path: '/back-office/payment-group',
            label: i18n('paymentGroup.menu'),
            permissionRequired: permissions.withdrawalRead,
          },
          {
            path: '/back-office/invoice',
            label: i18n('invoice.menu'),
            permissionRequired: permissions.withdrawalRead,
          },
        ],
      },

      {
        path: '/user',
        label: i18n('user.menu'),
        permissionRequired: permissions.userRead,
        icon: <PersonIcon />,
      },

      {
        path: '/back-office/settings',
        icon: <SettingsIcon />,
        label: i18n('settings.menu'),
        permissionRequired: permissions.admin,
      },

      {
        path: '/audit-logs',
        icon: <HistoryIcon />,
        label: i18n('auditLog.menu'),
        permissionRequired: permissions.auditLogRead,
      },
    ],
  },
};

export default MENUS;
