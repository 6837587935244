import { ComponentStyle, alpha } from 'src/@types/Style';
import { HEADER_DESKTOP_HEIGHT, HEADER_MOBILE_HEIGHT } from 'src/config';

const styles: ComponentStyle = {
  toolbar: {
    height: {
      xs: HEADER_MOBILE_HEIGHT,
      md: HEADER_DESKTOP_HEIGHT,
      lg: HEADER_DESKTOP_HEIGHT,
      xl: HEADER_DESKTOP_HEIGHT,
    },
    color: (theme) => theme.palette.text.primary,
    backgroundColor: (theme) => theme.palette.background.default,
    transition: (theme) =>
      theme.transitions.create(['height', 'background-color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
      }),
  },
  scrolling: {
    height: {
      xs: HEADER_MOBILE_HEIGHT,
      md: HEADER_DESKTOP_HEIGHT - 20,
      lg: HEADER_DESKTOP_HEIGHT - 20,
      xl: HEADER_DESKTOP_HEIGHT - 20,
    },
    backdropFilter: `blur(6px)`,
    WebkitBackdropFilter: `blur(6px)`,
    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
  },
  shadow: {
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: `calc(100% - 48px)`,
    boxShadow: (theme) => `-23px 16px 56px -8px ${alpha(theme.palette.grey[500], 1)}`,
  },
};

export default styles;
