import { CourseProps } from 'src/@types/course';
import { NoteType } from 'src/@types/note';
import authAxios from 'src/modules/shared/axios/authAxios';

export default class CourseService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/course/${id}`, body);

    return response.data;
  }

  static async doUseWallet(id) {
    const body = {
      id,
      paymentType: 'course',
    };

    await authAxios.post(`/payment/teacher/withwallet`, body);
  }

  static async patch(id, op, value?) {
    const response = await authAxios.patch(`/course/${id}`, { op, value });

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/course`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/course`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get<CourseProps>(`/course/${id}`);

    return response.data;
  }

  static async findEvent(courseId, moduleId, eventId) {
    const response = await authAxios.get(`/course/${courseId}/module/${moduleId}/event/${eventId}`);

    return response.data;
  }

  static async getDefaultFee() {
    const response = await authAxios.get(`/course/fee`);

    return response.data;
  }

  static async findPublic(id) {
    const response = await authAxios.get(`/public/course/${id}`);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/course`, {
      params,
    });

    return response.data;
  }

  static async export(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/course/export`, {
      params,
    });

    return response.data;
  }

  static async listPublic(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/public/course`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(`/course/autocomplete`, {
      params,
    });

    return response.data;
  }

  static async findStudent(courseId, studentId) {
    const response = await authAxios.get(`/course/${courseId}/student/${studentId}`);

    return response.data;
  }

  static async findNotes(courseId: string, eventId: string, moduleId: string): Promise<NoteType[]> {
    const response = await authAxios.get(`/course/${courseId}/module/${moduleId}/event/${eventId}/notes`);
    return response.data;
  }

  static async addNote(courseId: string, eventId: string, moduleId: string, note: string) {
    const body = {
      op: 'addNote',
      value: {
        moduleId,
        eventId,
        note,
      },
    };
    const response = await authAxios.patch(`/course/${courseId}`, body);

    return response.data;
  }
}
