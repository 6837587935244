import { Stack, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import { i18n } from 'src/i18n';

export default function NavbarDocs() {
  const userText = useSelector(authSelectors.selectCurrentUserNameOrEmailPrefix);

  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <div>
        <Typography gutterBottom variant="subtitle1">
          {i18n('components.navbarDocs.hi', userText)}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {i18n('components.navbarDocs.needHelp')}
        </Typography>
      </div>

      <Button href="" target="_blank" rel="noopener" variant="contained">
        {i18n('components.navbarDocs.doc')}
      </Button>
    </Stack>
  );
}
