import StudentTransactionService from 'src/modules/studentTransaction/studentTransactionService';
import selectors from 'src/modules/studentTransaction/list/studentTransactionListSelectors';
import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';

const prefix = 'STUDENT_TRANSACTION_LIST';

const studentTransactionListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected() {
    return {
      type: studentTransactionListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: studentTransactionListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: studentTransactionListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: studentTransactionListActions.RESETED,
    });

    dispatch(studentTransactionListActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentTransactionListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await StudentTransactionService.export(filter, selectors.selectOrderBy(getState()), null, null);

      new Exporter(i18n('wallet.exporterFileName')).transformAndExportAsExcelFile(response);

      dispatch({
        type: studentTransactionListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentTransactionListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: studentTransactionListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(studentTransactionListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: studentTransactionListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(studentTransactionListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(studentTransactionListActions.doFetch(filter, rawFilter, true));
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: studentTransactionListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });

        const response = await StudentTransactionService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: studentTransactionListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
            stats: response.stats,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: studentTransactionListActions.FETCH_ERROR,
        });
      }
    },
};

export default studentTransactionListActions;
