import authAxios from 'src/modules/shared/axios/authAxios';

export default class TeacherRequestService {
  static async patch(id, op, value) {
    const response = await authAxios.patch(`/teacher-request/${id}`, { op, value });

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/teacher-request/${id}`);
    return response.data;
  }

  static async fetch(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/teacher-request`, {
      params,
    });

    return response.data;
  }

  static async export(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/teacher-request/export`, {
      params,
    });

    return response.data;
  }
}
