/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */

export interface StorageType {
  id: string;
  folder: string;
  maxSizeInBytes: number;
  bypassWritingPermissions?: boolean;
  publicRead?: boolean;
}

export default class Storage {
  static get values() {
    return {
      courseImage: {
        id: 'courseImage',
        folder: 'course/image',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      categoryImage: {
        id: 'categoryImage',
        folder: 'category/image',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      courseDocument: {
        id: 'courseDocument',
        folder: 'course/document',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      userAvatarProfile: {
        id: 'userAvatarProfile',
        folder: 'user/avatar/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      courseActivity: {
        id: 'courseActivity',
        folder: 'course/activity',
        maxSizeInBytes: 10 * 1024 * 1024,
      },
      meetUpImage: {
        id: 'meetUpImage',
        folder: 'meetUp/image',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
    };
  }
}
