import { Conversation, conversationModeMap } from 'src/@types/chat';
import authSelectors from 'src/modules/auth/authSelectors';
import ChatService from 'src/modules/chat/chatService';
import chatConversationsActions from 'src/modules/chat/conversations/chatConversationsActions';
import chatConversationsSelectors from 'src/modules/chat/conversations/chatConversationsSelectors';
import { statusMap } from 'src/modules/chat/currentConversation/chatCurrentConversationReducers';
import chatCurrentConversationSelectors from 'src/modules/chat/currentConversation/chatCurrentConversationSelectors';
import notificationListActions from 'src/modules/notification/list/notificationListActions';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'CHAT_CURRENT_CONVERSATION';

const chatCurrentConversationActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  SEND_MESSAGE_STARTED: `${prefix}_SEND_MESSAGE_STARTED`,
  SEND_MESSAGE_SUCCESS: `${prefix}_SEND_MESSAGE_SUCCESS`,
  SEND_MESSAGE_NEW_CONVERSATION_SUCCESS: `${prefix}_SEND_MESSAGE_NEW_CONVERSATION_SUCCESS`,
  SEND_MESSAGE_ERROR: `${prefix}_SEND_MESSAGE_ERROR`,

  FETCH_NEW_MESSAGES_SUCCESS: `${prefix}_FETCH_NEW_MESSAGES_SUCCESS`,

  RESETED: `${prefix}_RESETED`,
  CREATE: `${prefix}_CREATE`,
  CHANGE_ACTIVE_ID: `${prefix}_CHANGE_ACTIVE_ID`,

  doReset: () => async (dispatch) => {
    dispatch({ type: chatCurrentConversationActions.RESETED });
  },

  doFetch: (activeConversationId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: chatCurrentConversationActions.FETCH_STARTED,
        payload: {
          activeConversationId,
        },
      });

      const conversation = await ChatService.getConversation(activeConversationId);

      dispatch({
        type: chatCurrentConversationActions.FETCH_SUCCESS,
        payload: {
          conversation,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({ type: chatCurrentConversationActions.FETCH_ERROR });
    }
  },

  doUpdateCurrentConversation: (newMessages, activeConversationId) => async (dispatch, getState) => {
    try {
      if (newMessages.length > 0) {
        dispatch({
          type: chatCurrentConversationActions.FETCH_NEW_MESSAGES_SUCCESS,
          payload: {
            newMessages,
          },
        });
        dispatch(chatCurrentConversationActions.markConversationAsRead(activeConversationId));
      }
    } catch (error) {
      Errors.handle(error);
    }
  },

  doFetchNewMessages: (activeConversationId) => async (dispatch, getState) => {
    try {
      const activeId = chatCurrentConversationSelectors.selectActiveConversationId(getState());
      const { messages } = chatCurrentConversationSelectors.selectConversation(getState());
      const lastMessage = messages[messages?.length - 1];
      const newMessages = await ChatService.fetchNewMessages(activeId, lastMessage?.id, lastMessage?.createdAt);

      if (newMessages.length > 0) {
        dispatch({
          type: chatCurrentConversationActions.FETCH_NEW_MESSAGES_SUCCESS,
          payload: {
            newMessages,
          },
        });
      }
    } catch (error) {
      Errors.handle(error);
    }
  },

  doCreate: (contact) => async (dispatch, getState) => {
    dispatch({
      type: chatCurrentConversationActions.CREATE,
      payload: {
        contact,
      },
    });
  },

  doSendMessage: (text) => async (dispatch, getState) => {
    try {
      dispatch({ type: chatCurrentConversationActions.SEND_MESSAGE_STARTED });

      const status = chatCurrentConversationSelectors.selectStatus(getState());

      if (status === statusMap.new) {
        const { participants } = chatCurrentConversationSelectors.selectConversation(getState());
        const { id: currentUserId } = authSelectors.selectCurrentUser(getState());
        const conversation = await ChatService.sendMessageToNewConversation(
          text,
          participants.filter(({ id }) => currentUserId !== id)[0].id,
        );

        dispatch({
          type: chatCurrentConversationActions.SEND_MESSAGE_NEW_CONVERSATION_SUCCESS,
          payload: { conversation },
        });
        dispatch(chatConversationsActions.addConversation(conversation));
      } else {
        const activeId = chatCurrentConversationSelectors.selectActiveConversationId(getState());
        const message = await ChatService.sendMessage(text, activeId);

        dispatch({
          type: chatCurrentConversationActions.SEND_MESSAGE_SUCCESS,
          payload: { message },
        });
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({ type: chatCurrentConversationActions.SEND_MESSAGE_ERROR });
    }
  },
  markConversationAsRead: (activeConversationId) => async (dispatch, getState) => {
    if (activeConversationId && activeConversationId?.length > 0) {
      await ChatService.markConversationAsRead(activeConversationId);
    }
  },

  doSetActiveConversationId: (activeConversationId) => async (dispatch, getState) => {
    dispatch({
      type: chatCurrentConversationActions.CHANGE_ACTIVE_ID,
      payload: activeConversationId,
    });
  },
  doSetActiveConversationIdFromCourseId: (courseId) => async (dispatch, getState) => {
    const conversations: Conversation[] = chatConversationsSelectors.selectRows(getState());

    const conversation = conversations.find(
      (conversation) => conversation.mode === conversationModeMap.OneToMany && conversation.course === courseId,
    );

    if (conversation?.id) {
      dispatch({
        type: chatCurrentConversationActions.CHANGE_ACTIVE_ID,
        payload: conversation.id,
      });
    }
  },
};

export default chatCurrentConversationActions;
