import { createSelector } from 'reselect';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import Permissions from 'src/security/permissions';

const selectPermissionToRead = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
  new PermissionChecker(currentUser).match(Permissions.values.userRead),
);

const selectPermissionToEdit = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
  new PermissionChecker(currentUser).match(Permissions.values.userEdit),
);

const selectPermissionToDestroy = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
  new PermissionChecker(currentUser).match(Permissions.values.userDestroy),
);

const selectPermissionToCreate = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
  new PermissionChecker(currentUser).match(Permissions.values.userCreate),
);

const selectPermissionToImport = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
  new PermissionChecker(currentUser).match(Permissions.values.userImport),
);
const selectPermissionToBackOffice = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
  new PermissionChecker(currentUser).match(Permissions.values.backOfficeZone),
);
const selectPermissionToTeacherZone = createSelector([authSelectors.selectCurrentUser], (currentUser) =>
  new PermissionChecker(currentUser).match(Permissions.values.teacherZone),
);

const userSelectors = {
  selectPermissionToRead,
  selectPermissionToEdit,
  selectPermissionToCreate,
  selectPermissionToImport,
  selectPermissionToDestroy,
  selectPermissionToBackOffice,
  selectPermissionToTeacherZone,
};

export default userSelectors;
