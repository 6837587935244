import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  navLink: {
    ...(theme) => theme.typography.subtitle2,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    textTransform: 'uppercase',
    transition: (theme) =>
      theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
      }),
    '&:hover': {
      opacity: 0.72,
      textDecoration: 'none',
    },
  },
  active: {
    fontWeight: 800,
    '&:after': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      width: '50%',
      height: '2px',
      margin: 'auto',
      backgroundColor: (theme) => theme.palette.primary.main,
    },
  },
  open: {
    color: (theme) => theme.palette.primary.main,
  },
  scrolling: {
    color: (theme) => theme.palette.text.primary,
  },
};

export default styles;
