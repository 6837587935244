import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import { navConfig, NavMobile } from '../../nav';
import styles from './HeaderMobile.styles';
import KnowmadersUniversityLogoSmall from 'src/view/shared/logos/KnowmadersUniversityLogoSmall';
import NotificationBell from 'src/view/shared/NotificationBell/NotificationBell';
import UserMenu from 'src/view/shared/UserMenu/UserMenu';
import { HEADER_MOBILE_HEIGHT } from 'src/config';
import useOffSetTop from 'src/hooks/useOffSetTop';

interface Props {
  transparentHeader: boolean;
}
const HeaderMobile: React.FC<Props> = (props) => {
  const { transparentHeader } = props;
  const isScrolling = useOffSetTop(HEADER_MOBILE_HEIGHT);
  const currentUser = useSelector(authSelectors.selectCurrentUser);

  return (
    <Box
      sx={[
        styles.mobile,
        styles.background,
        transparentHeader && styles.transparent,
        isScrolling && transparentHeader && styles.animationOnScroll,
      ]}
    >
      <Box sx={styles.menu}>
        <NavMobile navConfig={navConfig} />
      </Box>
      <Box sx={styles.logo}>
        <KnowmadersUniversityLogoSmall />
      </Box>
      <Box sx={styles.user}>
        {currentUser && (
          <Box sx={styles.wrapperMobile}>
            <NotificationBell />
            <UserMenu showName={false} size="small" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HeaderMobile;
