import { categoryClassificationMap } from 'src/@types/category';
import authAxios from 'src/modules/shared/axios/authAxios';

export default class CategoryService {
  static async patch(id, op, value) {
    const response = await authAxios.patch(`/category/${id}`, { op, value });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/category`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/category/${id}`);
    return response.data;
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/category/${id}`, body);

    return response.data;
  }

  static async fetch(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/category`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query) {
    const params = {
      query,
    };

    const response = await authAxios.get(`/category/autocomplete`, {
      params,
    });

    return response.data;
  }

  static async listTree() {
    const response = await authAxios.get(`/category/tree`, {});

    return response.data;
  }

  static async listTopCategories() {
    const params = {
      filter: {
        classification: categoryClassificationMap.category,
      },
    };

    const response = await authAxios.get(`/category`, {
      params,
    });

    return response.data;
  }

  static async destroy(ids) {
    const params = {
      ids: Array.isArray(ids) ? ids : [ids],
    };

    const response = await authAxios.delete(`/category`, {
      params,
    });

    return response.data;
  }
}
