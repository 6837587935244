import MeetUpService from 'src/modules/meetUp/meetUpService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import selectors from 'src/modules/meetUp/form/meetUpFormSelectors';
import { CourseStatus } from 'src/@types/course';

const prefix = 'MEETUP_FORM';

const meetUpFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  UPDATE_COURSE: `${prefix}_UPDATE_COURSE`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: meetUpFormActions.INIT_STARTED,
      });

      let record = {};
      const defaultConfiguration = await MeetUpService.getDefaultConfiguration();

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await MeetUpService.find(id);
      }

      dispatch({
        type: meetUpFormActions.INIT_SUCCESS,
        payload: { ...{ defaultConfiguration }, ...record },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: meetUpFormActions.INIT_ERROR,
      });

      getHistory().push('/teacher/meet-up');
    }
  },

  doUseWallet: (id) => async (dispatch) => {
    try {
      await MeetUpService.doUseWallet(id);
      window.open(`/teacher/meet-up/${id}/publicate?redirect_status=succeeded`, '_self');
    } catch (error) {
      Errors.handle(error);
    }
  },

  doCreate: (values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: meetUpFormActions.CREATE_STARTED,
      });

      const record = selectors.selectRecord(getState());
      const newRecord = await MeetUpService.create({ ...record, ...values });

      dispatch({
        type: meetUpFormActions.CREATE_SUCCESS,
        payload: newRecord,
      });

      Message.success(i18n('entities.meetUp.create.success'));

      if (newRecord.status === CourseStatus.readyToPublish) {
        getHistory().push(`/teacher/meet-up/${newRecord._id}/publicate`);
      } else {
        getHistory().push('/teacher/meet-up');
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: meetUpFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: meetUpFormActions.UPDATE_STARTED,
      });

      const record = selectors.selectRecord(getState());
      const newRecord = await MeetUpService.update(id, { ...record, ...values });

      dispatch({
        type: meetUpFormActions.UPDATE_SUCCESS,
        payload: newRecord,
      });

      Message.success(i18n('entities.meetUp.update.success'));

      if (newRecord.status === CourseStatus.readyToPublish) {
        getHistory().push(`/teacher/meet-up/${newRecord._id}/publicate`);
      } else {
        getHistory().push('/teacher/meet-up');
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: meetUpFormActions.UPDATE_ERROR,
      });
    }
  },

  doUpdateDirectly: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: meetUpFormActions.UPDATE_COURSE,
        payload: values,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: meetUpFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default meetUpFormActions;
