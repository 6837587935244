import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { HEADER_DESKTOP_HEIGHT } from 'src/config';
import useOffSetTop from 'src/hooks/useOffSetTop';
import authSelectors from 'src/modules/auth/authSelectors';
import { navConfig, NavDesktop } from '../../nav';
import styles from './HeaderDesktop.styles';
import SignButtons from 'src/view/shared/SignButtons/SignButtons';
import KnowmadersUniversityLogo from 'src/view/shared/logos/KnowmadersUniversityLogo';
import NotificationBell from 'src/view/shared/NotificationBell/NotificationBell';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import UserMenu from 'src/view/shared/UserMenu/UserMenu';

interface Props {
  transparentHeader: boolean;
}

const HeaderDesktop: React.FC<Props> = (props) => {
  const { transparentHeader } = props;
  const isScrolling = useOffSetTop(HEADER_DESKTOP_HEIGHT);
  const currentUser = useSelector(authSelectors.selectCurrentUser);

  return (
    <Box
      sx={[
        styles.desktop,
        styles.background,
        transparentHeader && styles.transparent,
        transparentHeader && isScrolling && styles.animationOnScroll,
      ]}
    >
      <Box sx={styles.logo}>
        <KnowmadersUniversityLogo sx={styles.logoSize} />
      </Box>
      <Box sx={styles.menu}>
        <NavDesktop sx={styles.navigation} isScrolling={isScrolling} navConfig={navConfig} />
      </Box>
      <Box sx={styles.user}>
        {currentUser ? (
          <Box sx={styles.wrapper}>
            <Button sx={styles.button} to={'/student/my-learning'} component={Link}>
              {i18n('navigation.myLearning')}
            </Button>
            <NotificationBell />
            <UserMenu />
          </Box>
        ) : (
          <SignButtons />
        )}
      </Box>
    </Box>
  );
};

export default HeaderDesktop;
