import actions from 'src/modules/meetUp/form/meetUpFormActions';

const initialData = {
  initLoading: false,
  saveLoading: false,
  record: null,
};

export default function meetUpFormReducers(state = initialData, { type, payload }) {
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      record: null,
      initLoading: true,
    };
  }

  if (type === actions.INIT_SUCCESS) {
    return {
      ...state,
      record: payload,
      initLoading: false,
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      record: null,
      initLoading: false,
    };
  }

  if (type === actions.CREATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.CREATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
      record: { ...(typeof state.record === 'object' ? state.record : {}), ...payload },
    };
  }

  if (type === actions.CREATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
      record: { ...(typeof state.record === 'object' ? state.record : {}), ...payload },
    };
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_COURSE) {
    return {
      ...state,
      record: { ...(typeof state.record === 'object' ? state.record : {}), ...payload },
    };
  }

  return state;
}
