import { Link as MaterialLink, LinkProps } from '@mui/material';
import styles from './NavLinkMobile.styles';
import { Link } from 'react-router-dom';

interface Props extends LinkProps {
  active?: boolean;
  to?: string;
  component?: Link;
}

const NavLinkMobile: React.FC<Props> = (props) => {
  const { active = false, ...otherProps } = props;
  return <MaterialLink {...otherProps} sx={[styles.navLink, active && styles.active]} />;
};

export default NavLinkMobile;
