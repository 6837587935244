import authAxios from 'src/modules/shared/axios/authAxios';

export default class ImporterService {
  static async importerTemplate(fields) {
    const response = await authAxios.post(`/importer/template`, { fields });

    return response.data;
  }

  static async excelToJson(file, fields) {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('fields', fields);

    const response = await authAxios.post(`/importer/excel-to-json`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  }
}
