import { i18n } from 'src/i18n';

export const PageLinks = [
  {
    order: '0',
    subheader: i18n('footerLinks.menu'),
    items: [
      { title: i18n('footerLinks.init'), path: '/' },
      { title: i18n('footerLinks.courses'), path: '/' },
      { title: i18n('footerLinks.meetups'), path: '/' },
      { title: i18n('footerLinks.knowmaders'), path: '/' },
      { title: i18n('footerLinks.coffeAndBeers'), path: '/' },
    ],
  },
  {
    order: '1',
    subheader: i18n('footerLinks.knowmadersExperience'),
    items: [
      { title: i18n('footerLinks.students'), path: '/' },
      { title: i18n('footerLinks.teachers'), path: '/' },
      { title: i18n('footerLinks.community'), path: '/' },
    ],
  },
];

export const navConfig = [
  {
    title: i18n('navigation.courses'),
    path: '/courses',
    position: 'left',
  },
  {
    title: i18n('navigation.meetUps'),
    path: '/meet-ups',
    position: 'left',
  },
  {
    title: i18n('navigation.knowmaders'),
    path: '/knowmaders',
    position: 'left',
  },
  {
    title: i18n('navigation.coffeeAndBeers'),
    path: '/coffee-and-beers',
    position: 'left',
  },
];
