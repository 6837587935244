import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: (theme) => theme.palette.common.white,
  },
  main: {
    mt: 5,
    width: { xs: '100vw', md: '90vw' },
  },
  texts: {
    p: 5,
    borderTopColor: (theme) => theme.palette.primary.main,
    borderTopStyle: 'solid',
    borderBottomColor: (theme) => theme.palette.primary.main,
    borderBottomStyle: 'solid',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: 2,
  },
  linksWrapper: {
    p: { xs: 1, md: 5 },
    borderTopColor: { md: (theme) => theme.palette.primary.main },
    borderTopStyle: { md: 'solid' },
    borderLeftColor: { md: (theme) => theme.palette.primary.main },
    borderLeftStyle: { md: 'solid' },
    borderBottomColor: (theme) => theme.palette.primary.main,
    borderBottomStyle: 'solid',
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  font: {
    fontFamily: 'FF Mark',
    fontWeight: 600,
  },
  arrow: {
    display: { xs: 'none', md: 'block', lg: 'block', xl: 'block' },
  },
};

export default styles;
