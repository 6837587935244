import list from 'src/modules/review/list/reviewListReducers';
import summary from 'src/modules/review/summary/reviewSummaryReducers';
import form from 'src/modules/review/form/reviewFormReducers';

import { combineReducers } from 'redux';

export default combineReducers({
  list,
  summary,
  form,
});
