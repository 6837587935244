import { tableStatusMap } from 'src/@types/table';
import actions from './coffeeAndBeersSummaryActions';
import { SummaryByCategory } from 'src/@types/summaryByCategory';

const TABLES_BY_SUBCATEGORY = 6;

export type TotalSummaryStateType = {
  loading: boolean;
  error: boolean;
  rows: SummaryByCategory[];
};

const initialData: TotalSummaryStateType = {
  loading: false,
  error: false,
  rows: [],
};

export default function coffeeAndBeersListReducers(state = initialData, { type, payload }) {
  if (type === actions.GET_CATEGORY_SUMMARY_PENDING) {
    return {
      ...state,
      loading: true,
      error: false,
    };
  }
  if (type === actions.GET_CATEGORY_SUMMARY_FULFILLED) {
    return {
      ...state,
      loading: false,
      error: false,
      rows: payload.subcategoriesList.map((category: SummaryByCategory['subcategory']) => {
        const data = payload.data?.find((categoryData: SummaryByCategory) => categoryData.subcategory === category);
        return {
          subcategory: category,
          total: data?.total ?? 0,
          tables: data?.tables ?? [],
        };
      }),
    };
  }
  if (type === actions.GET_CATEGORY_SUMMARY_REJECTED) {
    return {
      ...state,
      loading: false,
      error: true,
    };
  }
  if (type === actions.CLEAN) {
    return initialData;
  }
  if (type === actions.ADD_SUBCATEGORY_PARTICIPANT) {
    return {
      ...state,
      loading: false,
      error: false,
      rows: state.rows.map((summary: SummaryByCategory) => {
        if (summary.subcategory === payload.subcategoryId) {
          const tableIndex = summary.tables.findIndex((table) => table.id === payload.tableId);

          const newConnection = {
            startDate: new Date().toJSON(),
            drink: payload.drink,
          };

          const newParticipant = {
            user: { id: payload.userId, name: payload.userFullName, avatar: payload.userAvatar },
            connections: [newConnection],
          };

          if (tableIndex !== undefined && tableIndex !== -1) {
            const indexParticipant = summary.tables[tableIndex]?.participants?.findIndex(
              (participant) => participant.user.id === payload.userId,
            );
            if (indexParticipant !== undefined && indexParticipant !== -1) {
              // Participant rejoin
              return {
                ...summary,
                tables: summary.tables.map((table) =>
                  table.id === payload.tableId
                    ? {
                        ...table,
                        participants: table.participants?.map((participant) =>
                          participant.user.id === payload.userId
                            ? { ...participant, connections: [...participant.connections, newConnection] }
                            : participant,
                        ),
                      }
                    : table,
                ),
              };
            } else {
              // New participant
              summary.tables[tableIndex]?.participants?.push(newParticipant);
              return {
                ...summary,
                tables: summary.tables.map((table) =>
                  table.id === payload.tableId
                    ? {
                        ...table,
                        participants: [...(table.participants ?? []), newParticipant],
                      }
                    : table,
                ),
              };
            }
          } else if (summary.tables.length < TABLES_BY_SUBCATEGORY) {
            // Add table with participant
            return {
              ...summary,
              tables: [
                ...summary.tables,
                {
                  id: payload.tableId,
                  name: payload.tableName,
                  status: tableStatusMap.open,
                  participants: [newParticipant],
                  createdAt: new Date().toJSON(),
                  subcategory: payload.subcategoryId,
                },
              ],
            };
          }
        }
        return summary;
      }),
    };
  }
  if (type === actions.DELETE_SUBCATEGORY_PARTICIPANT) {
    return {
      ...state,
      loading: false,
      error: false,
      rows: state.rows.map((summary: SummaryByCategory) => ({
        ...summary,
        tables: summary.tables.map((table) =>
          table.id === payload.tableId
            ? {
                ...table,
                participants: (table.participants ?? []).map((participant) =>
                  participant.user.id === payload.userId
                    ? {
                        ...participant,
                        connections: participant.connections.map((connection) =>
                          !('endDate' in connection)
                            ? {
                                ...connection,
                                endDate: new Date().toJSON(),
                              }
                            : connection,
                        ),
                      }
                    : participant,
                ),
              }
            : table,
        ),
      })),
    };
  }
  if (type === actions.DELETE_SUBCATEGORY_TABLE) {
    return {
      ...state,
      loading: false,
      error: false,
      rows: state.rows.map((summary: SummaryByCategory) =>
        summary.subcategory === payload.subcategoryId
          ? { ...summary, tables: summary.tables.filter((table) => table.id !== payload.tableId) }
          : summary,
      ),
    };
  }

  return state;
}
