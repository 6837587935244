// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// SwaggerUI Documentation URL
// Leave black if documentation should be hidden
const apiDocumentationUrl = `/documentation`;

/**
 * Frontend URL.
 */
const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
const socketsUrl = process.env.REACT_APP_SOCKETS_URL;

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const urls = {
  frontendUrl,
  backendUrl,
  socketsUrl,
  apiDocumentationUrl,
  stripePublishableKey,
};

export default urls;
