import { Excel } from 'src/modules/shared/excel/excel';

export default class Exporter {
  excelFileName: string;

  constructor(excelFileName) {
    this.excelFileName = excelFileName;
  }

  transformAndExportAsExcelFile(response) {
    Excel.saveBlobAsExcelFile(response, this.excelFileName);
  }
}
