import CourseService from 'src/modules/course/courseService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import selectors from 'src/modules/course/form/courseFormSelectors';
import { CourseStatus } from 'src/@types/course';

const prefix = 'COURSE_FORM';

const courseFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  UPDATE_COURSE: `${prefix}_UPDATE_COURSE`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: courseFormActions.INIT_STARTED,
      });

      let record = {};
      const defaultFee = await CourseService.getDefaultFee();

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await CourseService.find(id);
      }

      dispatch({
        type: courseFormActions.INIT_SUCCESS,
        payload: { ...{ defaultFee }, ...record },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: courseFormActions.INIT_ERROR,
      });

      getHistory().push('/teacher/course');
    }
  },

  doUseWallet: (id) => async (dispatch) => {
    try {
      await CourseService.doUseWallet(id);
      window.open(`/teacher/course/${id}/publicate?redirect_status=succeeded`, '_self');
    } catch (error) {
      Errors.handle(error);
    }
  },

  doCreate: (values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: courseFormActions.CREATE_STARTED,
      });

      const record = selectors.selectRecord(getState());
      const newRecord = await CourseService.create({ ...record, ...values });

      dispatch({
        type: courseFormActions.CREATE_SUCCESS,
        payload: newRecord,
      });

      Message.success(i18n('entities.course.create.success'));

      if (newRecord.status === CourseStatus.readyToPublish) {
        getHistory().push(`/teacher/course/${newRecord._id}/publicate`);
      } else {
        getHistory().push('/teacher/course');
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: courseFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: courseFormActions.UPDATE_STARTED,
      });

      const record = selectors.selectRecord(getState());
      const newRecord = await CourseService.update(id, { ...record, ...values });

      dispatch({
        type: courseFormActions.UPDATE_SUCCESS,
        payload: newRecord,
      });

      Message.success(i18n('entities.course.update.success'));

      if (newRecord.status === CourseStatus.readyToPublish) {
        getHistory().push(`/teacher/course/${newRecord._id}/publicate`);
      } else {
        getHistory().push('/teacher/course');
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: courseFormActions.UPDATE_ERROR,
      });
    }
  },

  doUpdateDirectly: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: courseFormActions.UPDATE_COURSE,
        payload: values,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: courseFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default courseFormActions;
