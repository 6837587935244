import { ComponentStyle, alpha } from 'src/@types/Style';
import {
  DESKTOP_OFFSET_HEIGHT,
  DRAWER_COLLAPSE_WIDTH,
  DRAWER_WIDTH,
  HEADER_DASHBOARD_DESKTOP_HEIGHT,
  HEADER_DASHBOARD_MOBILE_HEIGHT,
} from 'src/config';

const styles: ComponentStyle = {
  container: {
    backdropFilter: `blur(6px)`,
    WebkitBackdropFilter: `blur(6px)`,
    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
    boxShadow: 'none',
    height: { xs: HEADER_DASHBOARD_MOBILE_HEIGHT, lg: HEADER_DASHBOARD_DESKTOP_HEIGHT },
    zIndex: (theme) => theme.zIndex.appBar + 1,
    transition: (theme) =>
      theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
      }),
    width: { xs: '100%', lg: `calc(100% - ${DRAWER_WIDTH + 1}px)` },
  },
  containerCollapsed: {
    width: { xs: '100%', lg: `calc(100% - ${DRAWER_COLLAPSE_WIDTH}px)` },
  },
  containerOffset: {
    height: { lg: DESKTOP_OFFSET_HEIGHT },
  },
  containerVertical: {
    width: '100%',
    height: { lg: DESKTOP_OFFSET_HEIGHT },
    backgroundColor: { lg: (theme) => theme.palette.background.default },
  },
  toolbar: {
    minHeight: '100% !important',
    px: { lg: 5 },
  },
  title: {
    flexGrow: 1,
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: {
      xs: 0.5,
      sm: 1.5,
    },
  },
};

export default styles;
