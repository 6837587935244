import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  icon: {
    color: 'text.primary',
  },
  newNotifications: {
    width: '.5rem',
    height: '.5rem',
    background: (theme) => theme.palette.error.main,
    borderRadius: '100%',
    position: 'absolute',
    top: '.6rem',
    right: '.6rem',
  },
  paper: {
    maxWidth: '400px',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    px: 2,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 25,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  buttonMore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 1,
  },
  menuItem: {
    mt: 1,
  },
};

export default styles;
