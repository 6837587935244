import { combineReducers } from 'redux';
import destroy from 'src/modules/exam/destroy/examDestroyReducers';
import form from 'src/modules/exam/form/examFormReducers';
import list from 'src/modules/exam/list/examListReducers';
import view from 'src/modules/exam/view/examViewReducers';
import publicView from 'src/modules/exam/publicView/examPublicViewReducers';

export default combineReducers({
  list,
  form,
  view,
  publicView,
  destroy,
});
