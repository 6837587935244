import CourseService from 'src/modules/course/courseService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'COURSE_EVENT_VIEW';

const courseEventViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (courseId: string, moduleId: string, eventId: string) => async (dispatch) => {
    try {
      dispatch({
        type: courseEventViewActions.FIND_STARTED,
      });

      const payload = await CourseService.findEvent(courseId, moduleId, eventId);

      dispatch({
        type: courseEventViewActions.FIND_SUCCESS,
        payload,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: courseEventViewActions.FIND_ERROR,
      });

      getHistory().push('/teacher/course');
    }
  },
};

export default courseEventViewActions;
