import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  background: {
    backgroundColor: (theme) => theme.palette.background.paper,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  animationOnScroll: {
    transition: 'background-color 500ms linear',
    backgroundColor: (theme) => theme.palette.background.paper,
  },
  mobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    px: 1,
  },
  menu: {
    flex: 4,
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    flex: 22,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  user: {
    flex: 6,
  },
  wrapperMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};

export default styles;
