import Permissions, { PermissionType } from 'src/security/permissions';

export interface RouteType {
  path: string;
  loader: () => Promise<{ default: React.ComponentType<any> }>;
  permissionRequired?: PermissionType | null;
  exact?: boolean;
  initialCollapsed?: boolean;
}
const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/notification',
    loader: () => import('src/view/notification/NotificationPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/chat',
    loader: () => import('src/view/chat/ChatPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/chat/:id',
    loader: () => import('src/view/chat/ChatPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/password-change',
    loader: () => import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/teacher-registration',
    loader: () => import('src/view/becomeTeacher/TeacherRegistrationPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/teacher-registration-requested',
    loader: () => import('src/view/becomeTeacher/TeacherRegistrationRequestedPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/courses/:id/inscription',
    loader: () => import('src/view/courseInscription/CourseInscriptionPage'),
    exact: true,
  },
  {
    path: '/meet-ups/:id/inscription',
    loader: () => import('src/view/meetUpInscription/MeetUpInscriptionPage'),
    exact: true,
  },
  {
    path: '/knowmader/:id',
    loader: () => import('src/view/knowmader/KnowmaderPage'),
    exact: true,
  },
];

const backOfficeRoutes = [
  {
    path: '/back-office',
    loader: () => import('src/view/dashboardLanding/DashboardLandingPage'),
    permissionRequired: permissions.backOfficeZone,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () => import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/teacher-request',
    loader: () => import('src/view/teacherRequest/list/TeacherRequestListPage'),
    permissionRequired: permissions.teacherRequestRead,
    exact: true,
  },
  {
    path: '/category',
    loader: () => import('src/view/category/table/CategoryTablePage'),
    permissionRequired: permissions.categoryCreate,
    exact: true,
  },
  {
    path: '/category/new',
    loader: () => import('src/view/category/form/CategoryFormPage'),
    permissionRequired: permissions.categoryCreate,
    exact: true,
  },
  {
    path: '/category/:id/edit',
    loader: () => import('src/view/category/form/CategoryFormPage'),
    permissionRequired: permissions.categoryEdit,
    exact: true,
  },
  {
    path: '/category/:id',
    loader: () => import('src/view/category/view/CategoryViewPage'),
    exact: true,
  },

  {
    path: '/teacher-request/:id',
    loader: () => import('src/view/teacherRequest/view/TeacherRequestViewPage'),
    permissionRequired: permissions.teacherRequestRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/back-office/withdrawal',
    loader: () => import('src/view/withdrawal/list/WithdrawalListPage'),
    permissionRequired: permissions.withdrawalRead,
    exact: true,
  },

  {
    path: '/back-office/payment-group',
    loader: () => import('src/view/paymentGroup/list/PaymentGroupListPage'),
    permissionRequired: permissions.withdrawalRead,
    exact: true,
  },

  {
    path: '/back-office/payment-group/:id',
    loader: () => import('src/view/paymentGroup/view/PaymentGroupViewPage'),
    permissionRequired: permissions.withdrawalRead,
    exact: true,
  },

  {
    path: '/back-office/invoice',
    loader: () => import('src/view/invoice/list/InvoiceListPage'),
    permissionRequired: permissions.withdrawalRead,
    exact: true,
  },
  {
    path: '/back-office/settings',
    loader: () => import('src/view/settings/edit/SettingsEditPage'),
    permissionRequired: permissions.admin,
    exact: true,
  },
].filter(Boolean);

const teacherRoutes = [
  {
    path: '/teacher-zone',
    loader: () => import('src/view/dashboardLanding/DashboardLandingTeacherPage'),
    permissionRequired: permissions.teacherZone,
    exact: true,
  },
  {
    path: '/teacher/agenda',
    loader: () => import('src/view/agenda/AgendaTeacherPage'),
    permissionRequired: permissions.teacherZone,
    exact: true,
  },
  {
    path: '/teacher/course',
    loader: () => import('src/view/course/list/CourseListPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },
  {
    path: '/teacher/course/new',
    loader: () => import('src/view/course/form/CourseFormPage'),
    permissionRequired: permissions.courseCreate,
    exact: true,
  },
  {
    path: '/teacher/course/:id/edit',
    loader: () => import('src/view/course/form/CourseFormPage'),
    permissionRequired: permissions.courseEdit,
    exact: true,
  },
  {
    path: '/teacher/course/:id',
    loader: () => import('src/view/course/view/CourseTimelineViewPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },
  {
    path: '/teacher/course/:id/publicate',
    loader: () => import('src/view/course/publicate/CoursePublicatePage'),
    permissionRequired: permissions.courseEdit,
    exact: true,
  },
  {
    path: '/teacher/course/:id/detail',
    loader: () => import('src/view/course/view/CourseViewPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },
  {
    path: '/teacher/course/:id/messages',
    loader: () => import('src/view/course/view/CourseTimelineViewPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },
  {
    path: '/teacher/course/:id/:moduleId',
    loader: () => import('src/view/course/view/CourseTimelineModuleViewPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },
  {
    path: '/teacher/course/:id/module/:moduleId/event/:eventId',
    loader: () => import('src/view/course/view/CourseEventViewPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },
  {
    path: '/teacher/course/:id/module/:moduleId/event/:eventId/live',
    loader: () => import('src/view/course/live/CourseLivePage'),
    permissionRequired: permissions.courseRead,
    exact: true,
    initialCollapsed: true,
  },
  {
    path: '/teacher/course/:courseId/student/:studentId',
    loader: () => import('src/view/student/StudentPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },

  {
    path: '/teacher/exam',
    loader: () => import('src/view/exam/list/ExamListPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },
  {
    path: '/teacher/exam/new',
    loader: () => import('src/view/exam/form/ExamFormPage'),
    permissionRequired: permissions.courseCreate,
    exact: true,
  },
  {
    path: '/teacher/exam/:id/edit',
    loader: () => import('src/view/exam/form/ExamFormPage'),
    permissionRequired: permissions.courseEdit,
    exact: true,
  },
  {
    path: '/teacher/exam/:id',
    loader: () => import('src/view/exam/view/ExamViewPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },

  {
    path: '/teacher/wallet',
    loader: () => import('src/view/teacherWallet/wallet/MyTeacherWalletPage'),
    permissionRequired: permissions.teacherZone,
    exact: true,
  },
  {
    path: '/teacher/wallet/deposit',
    loader: () => import('src/view/teacherWallet/deposit/MyTeacherWalletDepositPage'),
    permissionRequired: permissions.teacherZone,
    exact: true,
  },
  {
    path: '/teacher/wallet/withdrawal',
    loader: () => import('src/view/teacherWallet/withdrawal/MyTeacherWalletWithdrawalPage'),
    permissionRequired: permissions.teacherZone,
    exact: true,
  },

  {
    path: '/teacher/invoice',
    loader: () => import('src/view/invoice/teacherList/InvoiceTeacherListPage'),
    permissionRequired: permissions.teacherZone,
    exact: true,
  },

  {
    path: '/teacher/meet-up',
    loader: () => import('src/view/meetUp/list/MeetUpListPage'),
    permissionRequired: permissions.teacherZone,
    exact: true,
  },
  {
    path: '/teacher/meet-up/new',
    loader: () => import('src/view/meetUp/form/MeetUpFormPage'),
    permissionRequired: permissions.courseCreate,
    exact: true,
  },
  {
    path: '/teacher/meet-up/:id/edit',
    loader: () => import('src/view/meetUp/form/MeetUpFormPage'),
    permissionRequired: permissions.courseEdit,
    exact: true,
  },
  {
    path: '/teacher/meet-up/:id',
    loader: () => import('src/view/meetUp/view/MeetUpTimelineViewPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },
  {
    path: '/teacher/meet-up/:id/detail',
    loader: () => import('src/view/meetUp/view/MeetUpViewPage'),
    permissionRequired: permissions.courseRead,
    exact: true,
  },
  {
    path: '/teacher/meet-up/:id/publicate',
    loader: () => import('src/view/meetUp/publicate/MeetUpPublicatePage'),
    permissionRequired: permissions.courseEdit,
    exact: true,
  },
  {
    path: '/teacher/meet-up/:id/live',
    loader: () => import('src/view/meetUp/live/MeetUpLivePage'),
    permissionRequired: permissions.courseRead,
    exact: true,
    initialCollapsed: true,
  },
].filter(Boolean);

const studentRoutes = [
  {
    path: '/student/my-learning',
    loader: () => import('src/view/agenda/AgendaPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-courses',
    loader: () => import('src/view/courseStudent/list/CourseStudentListPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-courses/:id',
    loader: () => import('src/view/courseStudent/view/CourseStudentViewPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-courses/:id/messages',
    loader: () => import('src/view/courseStudent/view/CourseStudentViewPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-courses/:id/diploma',
    loader: () => import('src/view/courseStudent/view/CourseStudentViewPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-courses/:id/module/:moduleId',
    loader: () => import('src/view/courseStudent/view/CourseModuleStudentViewPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-courses/:id/module/:moduleId/event/:eventId',
    loader: () => import('src/view/courseStudent/viewEvent/CourseStudentEventViewPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-courses/:id/module/:moduleId/event/:eventId/live',
    loader: () => import('src/view/courseStudent/live/CourseStudentLivePage'),
    permissionRequired: permissions.myLearning,
    exact: true,
    initialCollapsed: true,
  },
  {
    path: '/student/my-courses/:id/module/:moduleId/event/:eventId/exam',
    loader: () => import('src/view/courseStudent/exam/CourseStudentExamPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-courses/:id/first-checkpoint',
    loader: () => import('src/view/courseStudent/view/CourseFirstCheckpointFormPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/wallet',
    loader: () => import('src/view/studentWallet/wallet/MyStudentWalletPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/wallet/withdrawal',
    loader: () => import('src/view/studentWallet/withdrawal/MyStudentWalletWithdrawalPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-meet-ups',
    loader: () => import('src/view/meetUpStudent/list/MeetUpStudentListPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-meet-ups/:id',
    loader: () => import('src/view/meetUpStudent/view/MeetUpStudentViewPage'),
    permissionRequired: permissions.myLearning,
    exact: true,
  },
  {
    path: '/student/my-meet-ups/:id/live',
    loader: () => import('src/view/meetUpStudent/live/MeetUpStudentLivePage'),
    permissionRequired: permissions.myLearning,
    exact: true,
    initialCollapsed: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/login',
    loader: () => import('src/view/auth/LoginPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () => import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () => import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () => import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/',
    loader: () => import('src/view/home/HomePage'),
  },
  {
    path: '/maintenance',
    loader: () => import('src/view/maintenance/MaintenancePage'),
  },
  {
    path: '/courses',
    loader: () => import('src/view/courses/CoursesPage'),
    exact: true,
  },
  {
    path: '/meet-ups',
    loader: () => import('src/view/meetUps/MeetUpsPage'),
    exact: true,
  },
  {
    path: '/meet-ups/:id',
    loader: () => import('src/view/meetUpLanding/MeetUpPage'),
    exact: true,
  },
  {
    path: '/become-a-teacher',
    loader: () => import('src/view/becomeTeacher/BecomeTeacherPage'),
    exact: true,
  },
  {
    path: '/student-experience',
    loader: () => import('src/view/studentExperience/StudentExperiencePage'),
    exact: true,
  },
  {
    path: '/community-experience',
    loader: () => import('src/view/communityExperience/CommunityExperiencePage'),
    exact: true,
  },
  {
    path: '/courses/:id',
    loader: () => import('src/view/courseLanding/CoursePage'),
    exact: true,
  },
  {
    path: '/diploma/:id',
    loader: () => import('src/view/diploma/view/DiplomaViewPage'),
    exact: true,
  },
  {
    path: '/knowmaders',
    loader: () => import('src/view/knowmaders/KnowmadersPage'),
    exact: true,
  },
  {
    path: '/coffee-and-beers',
    loader: () => import('src/view/coffeeAndBeers/CoffeeAndBeersCategoryPage'),
    exact: true,
  },
  {
    path: '/coffee-and-beers/:id',
    loader: () => import('src/view/coffeeAndBeers/CoffeeAndBeersSubcategoryPage'),
    exact: true,
  },
  {
    path: '/coffee-and-beers/:id/subcategory/:subcategoryId',
    loader: () => import('src/view/coffeeAndBeers/CoffeeAndBeersCoffeeShopPage'),
    exact: true,
  },
  {
    path: '/teacher/:id',
    loader: () => import('src/view/teacherLanding/TeacherPage'),
    exact: true,
  },
  {
    path: '/support',
    loader: () => import('src/view/support/SupportPage'),
  },
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/auth/registration',
    loader: () => import('src/view/auth/RegistrationPage'),
  },
  {
    path: '/403',
    loader: () => import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () => import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () => import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

const routes = {
  privateRoutes,
  backOfficeRoutes,
  teacherRoutes,
  studentRoutes,
  publicRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};

export default routes;
