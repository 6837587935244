import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import notification from 'src/modules/notification/notificationReducers';
import course from 'src/modules/course/courseReducers';
import chat from 'src/modules/chat/chatReducers';
import exam from 'src/modules/exam/examReducers';
import invoice from 'src/modules/invoice/invoiceReducers';
import courseStudent from 'src/modules/courseStudent/courseStudentReducers';
import knowmader from 'src/modules/knowmader/knowmaderReducers';
import studentTransaction from 'src/modules/studentTransaction/studentTransactionReducers';
import teacherRequest from 'src/modules/teacherRequest/teacherRequestReducers';
import category from 'src/modules/category/categoryReducers';
import teacherTransaction from 'src/modules/teacherTransaction/teacherTransactionReducers';
import paymentGroup from 'src/modules/paymentGroup/paymentGroupReducers';
import withdrawal from 'src/modules/withdrawal/withdrawalReducers';
import coffeeAndBeers from 'src/modules/coffeeAndBeers/coffeeAndBeersReducers';
import meetUp from 'src/modules/meetUp/meetUpReducers';
import meetUpStudent from 'src/modules/meetUpStudent/meetUpStudentReducers';
import settings from 'src/modules/settings/settingsReducers';

import review from 'src/modules/review/reviewReducers';
import { combineReducers } from 'redux';

export default function reducers(history) {
  return combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    user,
    auditLog,
    category,
    course,
    chat,
    exam,
    invoice,
    courseStudent,
    knowmader,
    teacherRequest,
    teacherTransaction,
    review,
    studentTransaction,
    notification,
    paymentGroup,
    withdrawal,
    coffeeAndBeers,
    meetUp,
    meetUpStudent,
    settings,
  });
}
