import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  background: {
    backgroundColor: (theme) => theme.palette.background.paper,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  animationOnScroll: {
    transition: 'background-color 500ms linear',
    backgroundColor: (theme) => theme.palette.background.paper,
  },
  desktop: {
    width: '100%',
    height: 'inherit',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '[col0] 1fr [col1] 4fr [col2] auto [col3] 16fr [col4] auto [col5] 1fr [col6] ',
  },
  logo: {
    gridColumnStart: 'col1',
    gridColumnEnd: 'col2',
    justifySelf: 'center',
  },
  menu: {
    gridColumnStart: 'col3',
    gridColumnEnd: 'col4',
    mt: 6,
  },
  user: {
    mt: 6,
    gridColumnStart: 'col4',
    gridColumnEnd: 'col5',
  },
  wrapper: {
    display: 'flex',
    ml: 'auto',
    columnGap: 1,
    alignItems: 'center',
  },
  button: {
    fontWeight: 600,
  },
  navigation: {
    flexGrow: 1,
  },
  logoSize: {
    '& svg': {
      width: '200px',
    },
  },
};

export default styles;
