import actions from 'src/modules/category/map/categoryMapActions';

const initialData = {
  map: undefined,
  lists: undefined,
};

export default function categoryListReducers(state = initialData, { type, payload }) {
  if (type === actions.AUTH_INIT_SUCCESS) {
    const mapAndLists = payload.categories.reduce(
      ({ map, lists }, category) => {
        const categoryListIds = category.list.map((category) => category._id);
        const categoriesToMap = category.list.reduce((acc, category) => {
          return { ...acc, [category._id]: category };
        }, {});
        return { map: { ...map, ...categoriesToMap }, lists: { ...lists, [category.classification]: categoryListIds } };
      },
      { map: {}, lists: {} },
    );
    return {
      ...state,
      ...mapAndLists,
    };
  }

  if (type === actions.AUTH_INIT_ERROR) {
    return {
      ...initialData,
    };
  }

  return state;
}
