import ExamService from 'src/modules/exam/examService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'EXAM_PUBLIC_VIEW';

const courseViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  INSCRIBED_STARTED: `${prefix}_INSCRIBED_STARTED`,
  INSCRIBED_SUCCESS: `${prefix}_INSCRIBED_SUCCESS`,
  INSCRIBED_ERROR: `${prefix}_INSCRIBED_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: courseViewActions.FIND_STARTED,
      });

      const record = await ExamService.findPublic(id);

      dispatch({
        type: courseViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: courseViewActions.FIND_ERROR,
      });

      getHistory().push('/courses');
    }
  },

  markAsInscribed: (courseId, userId) => async (dispatch) => {
    try {
      dispatch({ type: courseViewActions.INSCRIBED_STARTED });

      const suscribed = await ExamService.patch(courseId, 'doInscribed', { preinscripted: userId });

      if (suscribed) {
        dispatch({
          type: courseViewActions.INSCRIBED_SUCCESS,
          payload: { suscribed, userId },
        });
      } else {
        dispatch({ type: courseViewActions.INSCRIBED_ERROR });
        dispatch(courseViewActions.doFind(courseId));
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({ type: courseViewActions.INSCRIBED_ERROR });
    }
  },
};

export default courseViewActions;
