import { ComponentStyle, alpha } from 'src/@types/Style';
import { DRAWER_COLLAPSE_WIDTH, DRAWER_WIDTH } from 'src/config';

const styles: ComponentStyle = {
  container: {
    width: { lg: DRAWER_WIDTH },
    flexShrink: { lg: 0 },
    transition: (theme) => ({
      lg: theme.transitions.create('width', {
        duration: theme.transitions.duration.shorter,
      }),
    }),
  },
  containerCollapse: {
    width: { lg: DRAWER_COLLAPSE_WIDTH },
  },
  collapseClick: {
    position: 'absolute',
  },
  paperMobileDrawer: {
    width: DRAWER_WIDTH,
  },
  paperDesktopDrawer: {
    width: DRAWER_WIDTH,
    borderRightStyle: 'solid',
    bgcolor: 'background.default',
    transition: (theme) =>
      theme.transitions.create('width', {
        duration: theme.transitions.duration.standard,
      }),
  },
  paperDesktopDrawerCollapse: {
    width: DRAWER_COLLAPSE_WIDTH,
  },
  paperDesktopDrawerCollapseHover: {
    backdropFilter: `blur(6px)`,
    WebkitBackdropFilter: `blur(6px)`,
    backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
    boxShadow: (theme) => theme.customShadows.z24,
  },
};

export default styles;
