import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import styles from './SignButtons.styles';

interface Props {
  direction?: 'column' | 'row';
}

const SignButtons: React.FC<Props> = (props) => {
  const { direction = 'row' } = props;

  const goToRegistration = (location) => {
    return { pathname: '/auth/registration', state: { from: location?.pathname } };
  };

  const goToLogin = (location) => {
    return { pathname: '/auth/login', state: { from: location?.pathname } };
  };

  return (
    <Box sx={direction === 'row' ? styles.ctaWrapperRow : styles.ctaWrapperColumn}>
      <Button
        fullWidth={direction === 'column'}
        sx={styles.button}
        variant="outlined"
        component={Link}
        to={goToRegistration}
      >
        {i18n('components.footer.signUp')}
      </Button>
      <Button
        sx={styles.button}
        fullWidth={direction === 'column'}
        variant="contained"
        component={Link}
        to={goToLogin}
        startIcon={<PersonOutlineIcon sx={styles.whiteIcon} />}
      >
        {i18n('components.footer.signIn')}
      </Button>
    </Box>
  );
};

export default SignButtons;
