import { PaymentGroupType } from 'src/@types/transaction';
import authAxios from 'src/modules/shared/axios/authAxios';

export default class PaymentGroupService {
  static async create(transactionIds: string[]) {
    const body = {
      transactionIds,
    };

    const response = await authAxios.post(`/payment-group`, body);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/payment-group`, {
      params,
    });

    return response.data;
  }

  static async find(id): Promise<PaymentGroupType> {
    const response = await authAxios.get<PaymentGroupType>(`/payment-group/${id}`);

    return response.data;
  }

  static async export(id) {
    const response = await authAxios.get(`/payment-group/${id}/export`);

    return response.data;
  }
}
