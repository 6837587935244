// @type
import { SettingsValueProps } from '../@types/settings';

const config = require(`./${process.env.REACT_APP_ENVIRONMENT}`).default;

// SETTINGS
// ----------------------------------------------------------------------

export const defaultSettings = {
  // light | dark
  themeMode: 'light',
  // ltr | rtl
  themeDirection: 'ltr',
  //  default | blueOrange | greenOrange | purpleTeal | cyanYellow | pinkCyan
  themeColorPresets: 'default',
} as SettingsValueProps;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER_DASHBOARD_DESKTOP_HEIGHT = 100;
export const HEADER_DASHBOARD_MOBILE_HEIGHT = 64;
export const HEADER_MOBILE_HEIGHT = 64;
export const HEADER_DESKTOP_HEIGHT = 150;
export const DRAWER_WIDTH = 280;
export const DRAWER_COLLAPSE_WIDTH = 88;
export const DESKTOP_OFFSET_HEIGHT = 92 - 32;

export const DRAWER_ITEM_ROOT_HEIGHT = 48;
export const DRAWER_ITEM_SUB_HEIGHT = 40;

export const COURSE_REGISTRATION_WINDOW = 12;
export const COURSE_DEADMAN_WINDOW = 48;

export default config;
