import authAxios from 'src/modules/shared/axios/authAxios';

export default class CourseStudentService {
  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/student/course`, {
      params,
    });

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/student/course/${id}`);

    return response.data;
  }

  static async findDiploma(courseId) {
    const response = await authAxios.get(`/diploma/course/${courseId}`);

    return response.data;
  }

  static async findDiplomaById(diplomaId) {
    const response = await authAxios.get(`/diploma/${diplomaId}`);

    return response.data;
  }

  static async generateDiploma(courseId) {
    const body = {
      courseId,
    };

    const response = await authAxios.post(`/diploma`, body);

    return response.data;
  }

  static async patch(id, op, value?) {
    const response = await authAxios.patch(`/student/course/${id}`, { op, value });

    return response.data;
  }

  static async findEventDetail(id, moduleId, eventId) {
    const response = await authAxios.get(`/student/course/${id}/module/${moduleId}/event/${eventId}`);

    return response.data;
  }

  static async doExam(id, moduleId, eventId) {
    const response = await authAxios.get(`/student/course/${id}/module/${moduleId}/event/${eventId}/exam`);

    return response.data;
  }
  static async sendExam(courseId, moduleId, eventId, data) {
    const response = await authAxios.patch(`/student/course/${courseId}/module/${moduleId}/event/${eventId}`, {
      op: 'sendExam',
      value: data,
    });

    return response.data;
  }

  static async startExam(courseId, moduleId, eventId) {
    const response = await authAxios.patch(`/student/course/${courseId}/module/${moduleId}/event/${eventId}`, {
      op: 'startExam',
      value: true,
    });

    return response.data;
  }

  static async uploadEventActivityResult(courseId, moduleId, eventId, activity) {
    const response = await authAxios.patch(`/student/course/${courseId}/module/${moduleId}/event/${eventId}`, {
      op: 'uploadEventActivityResult',
      value: activity,
    });

    return response.data;
  }

  static async startSessionAttendance(courseId, moduleId, eventId) {
    const response = await authAxios.patch(`/student/course/${courseId}/module/${moduleId}/event/${eventId}`, {
      op: 'startSessionAttendance',
    });

    return response.data;
  }

  static async finishSessionAttendance(courseId, moduleId, eventId, attendanceId) {
    const response = await authAxios.patch(`/student/course/${courseId}/module/${moduleId}/event/${eventId}`, {
      op: 'finishSessionAttendance',
      value: { attendanceId },
    });

    return response.data;
  }
}
