import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';
import { Footer, FooterSimple } from 'src/view/layout/footer';
import { Header, HeaderSimple } from 'src/view/layout/header';

// ----------------------------------------------------------------------

type Props = BoxProps & {
  children: ReactNode;
  disabledHeader?: boolean;
  disabledFooter?: boolean;
  simpleHeader?: boolean;
  simpleFooter?: boolean;
  showCta?: boolean;
  transparentHeader?: boolean;
};

const Layout: React.FC<Props> = (props) => {
  const {
    children,
    disabledHeader,
    disabledFooter,
    simpleHeader,
    simpleFooter,
    showCta = false,
    sx,
    transparentHeader = false,
  } = props;

  return (
    <Box sx={sx}>
      {!disabledHeader && simpleHeader && <HeaderSimple />}
      {!disabledHeader && !simpleHeader && <Header transparentHeader={transparentHeader} />}
      {children}
      {!disabledFooter && simpleFooter && <FooterSimple />}
      {!disabledFooter && !simpleFooter && <Footer showCta={showCta} />}
    </Box>
  );
};

export default Layout;
