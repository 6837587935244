import authAxios from 'src/modules/shared/axios/authAxios';

export default class ReviewService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/review/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/review`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/review`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/review/${id}`);

    return response.data;
  }

  static async findSummary(args) {
    const params = {
      args,
    };
    const response = await authAxios.get(`/review/summary`, {
      params,
    });

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/review`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(`/review/autocomplete`, {
      params,
    });

    return response.data;
  }
}
