import 'simplebar/src/simplebar.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { i18n, init as i18nInit } from 'src/i18n';
import AuthService from 'src/modules/auth/authService';
import { AuthToken } from './modules/auth/authToken';
import { HeaderProvider } from 'src/contexts/HeaderContext';

(async function () {
  const isSocialOnboardRequested = AuthService.isSocialOnboardRequested();
  AuthToken.applyFromLocationUrlIfExists();
  if (isSocialOnboardRequested) {
    await AuthService.socialOnboard();
  }
  await i18nInit();

  const App = require('./App').default;
  document.title = i18n('app.title');
  ReactDOM.render(
    <HelmetProvider>
      <HeaderProvider>
        <App />
      </HeaderProvider>
    </HelmetProvider>,
    document.getElementById('root'),
  );
})();
