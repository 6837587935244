import authSelectors from 'src/modules/auth/authSelectors';
import ChatService from 'src/modules/chat/chatService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'CHAT_CONVERSATIONS';

const chatConversationsActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  // SET_COURSE_ID: `${prefix}_SET_COURSE_ID`,
  RESET: `${prefix}_RESET`,
  ADD_CONVERSATION: `${prefix}_ADD_CONVERSATION`,
  READ_MESSAGE: `${prefix}_READ_MESSAGE`,

  // doReset: () => async (dispatch) => {
  //   dispatch({ type: chatConversationsActions.RESET });
  // },

  // doSetCourse: (courseId) => async (dispatch) => {
  //   dispatch({
  //     type: chatConversationsActions.SET_COURSE_ID,
  //     payload: {
  //       courseId,
  //     },
  //   });
  // },

  addConversation: (conversation) => async (dispatch) => {
    dispatch({
      type: chatConversationsActions.ADD_CONVERSATION,
      payload: {
        conversation,
      },
    });
  },

  doFetch: (courseId?) => async (dispatch, getState) => {
    try {
      dispatch({ type: chatConversationsActions.FETCH_STARTED });

      const response = await ChatService.listConversations();

      dispatch({
        type: chatConversationsActions.FETCH_SUCCESS,
        payload: {
          rows: response,
          count: response.length,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({ type: chatConversationsActions.FETCH_ERROR });
    }
  },

  markConversationAsRead: (id) => async (dispatch, getState) => {
    if (id && id?.length > 0) {
      const { id: userId } = authSelectors.selectCurrentUser(getState());
      dispatch({
        type: chatConversationsActions.READ_MESSAGE,
        payload: { id, userId },
      });
    }
  },

  // doUpdateConversations: (conversations) => async (dispatch, getState) => {
  //   try {
  //     dispatch({
  //       type: chatConversationsActions.FETCH_SUCCESS,
  //       payload: {
  //         rows: conversations,
  //         count: conversations.length,
  //       },
  //     });
  //   } catch (error) {
  //     Errors.handle(error);

  //     dispatch({ type: chatConversationsActions.FETCH_ERROR });
  //   }
  // },
};

export default chatConversationsActions;
