import { useState } from 'react';
import { Box } from '@mui/material';
import DashboardHeader from './DashboardHeader';
import NavbarVertical from './navbar/NavbarVertical';
import MENUS from 'src/view/menus';
import styles from './Layout.styles';
import { RolesType } from 'src/security/roles';

interface Props {
  type: RolesType;
  initialCollapsed?: boolean;
}

const Layout: React.FC<Props> = (props) => {
  const { type, children, initialCollapsed = false } = props;
  const menu = [MENUS[type]] ?? [];

  const [isOpenSidebarMobile, setOpen] = useState(false);
  const openSidebarMobile = () => setOpen(true);
  const closeSidebarMobile = () => setOpen(false);

  const [isCollapsedSidebar, setCollapsed] = useState(initialCollapsed);
  const toggleSidebarDesktop = () => setCollapsed((state) => !state);

  const [isSidebarHover, setHover] = useState(false);
  const onHoverEnter = () => setHover(true);
  const onHoverLeave = () => setHover(false);

  return (
    <Box sx={styles.container}>
      <DashboardHeader isCollapse={isCollapsedSidebar && !isSidebarHover} openSidebarMobile={openSidebarMobile} />
      <NavbarVertical
        menu={menu}
        isOpenSidebarMobile={isOpenSidebarMobile}
        closeSidebarMobile={closeSidebarMobile}
        isCollapse={isCollapsedSidebar}
        toggleSidebarDesktop={toggleSidebarDesktop}
        isSidebarHover={isSidebarHover}
        onHoverEnter={onHoverEnter}
        onHoverLeave={onHoverLeave}
      />
      <Box component="main" sx={[styles.main, isCollapsedSidebar && isSidebarHover && styles.hoverOnMainCollapsed]}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
