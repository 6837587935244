import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  logo: {
    display: 'flex',
    justifyContent: 'center',

    '& svg': {
      width: '100%',
      height: 'auto',
    },

    '& path': {
      fill: (theme) => theme.palette.primary.main,
    },
  },
};

export default styles;
