import { useState } from 'react';
import { Box, Button, Drawer, IconButton } from '@mui/material';
import Scrollbar from 'src/view/shared/Scrollbar';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import NavItemMobile from 'src/view/layout/nav/NavItemMobile/NavItemMobile';
import { NavItemProps } from 'src/@types/layout';
import { Style } from 'src/@types/Style';
import styles from './NavMobile.styles';
import SignButtons from 'src/view/shared/SignButtons/SignButtons';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import KnowmadersUniversityLogo from 'src/view/shared/logos/KnowmadersUniversityLogo';

interface Props {
  navConfig: NavItemProps[];
  sx?: Style;
}

const NavMobile: React.FC<Props> = (props) => {
  const { navConfig, sx = {} } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  return (
    <>
      <IconButton aria-label="menu" size="large" color="inherit" onClick={handleDrawerOpen} sx={sx}>
        <MenuIcon fontSize="large" />
      </IconButton>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: styles.paper }}
      >
        <Scrollbar>
          <Box sx={styles.logo}>
            <KnowmadersUniversityLogo />
          </Box>

          <Box>
            {navConfig.map((link) => (
              <NavItemMobile key={link.title} item={link} />
            ))}
          </Box>
        </Scrollbar>
        <Box>
          <Divider orientation="horizontal" sx={styles.divider} />
          {!currentUser ? (
            <>
              <SignButtons direction="column" />
            </>
          ) : (
            <>
              <Button fullWidth variant="contained" sx={styles.button} to={'/student/my-learning'} component={Link}>
                {i18n('navigation.myLearning')}
              </Button>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default NavMobile;
