import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  app: {
    boxShadow: 0,
    bgcolor: 'transparent',
  },
  toolbar: {
    px: 2,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoSize: {
    '& svg': {
      width: '200px',
    },
  },
};

export default styles;
