import { ReactNode, createContext, useState } from 'react';

// ----------------------------------------------------------------------

export type HeaderContextProps = {
  title?: string;
  setTitle: (string) => void;
};

const initialState: HeaderContextProps = {
  title: undefined,
  setTitle: () => {},
};

const HeaderContext = createContext(initialState);

type HeaderProviderProps = {
  children: ReactNode;
};

function HeaderProvider({ children }: HeaderProviderProps) {
  const [title, setTitle] = useState<string | undefined>();

  const updateTitle = (title: string) => {
    setTitle(title);
  };

  return (
    <HeaderContext.Provider
      value={{
        title,
        setTitle: updateTitle,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}

export { HeaderProvider, HeaderContext };
