import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/view/shared/Iconify';
import { IconButtonAnimate } from 'src/view/shared/animate';
import { HEADER_DESKTOP_HEIGHT } from 'src/config';
import NotificationBell from 'src/view/shared/NotificationBell/NotificationBell';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import useHeader from 'src/hooks/useHeader';
import UserMenu from 'src/view/shared/UserMenu/UserMenu';
import styles from './DashboardHeader.styles';

type Props = {
  openSidebarMobile: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

const DashboardHeader: React.FC<Props> = (props) => {
  const { openSidebarMobile, isCollapse = false, verticalLayout = false } = props;
  const isOffset = useOffSetTop(HEADER_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const { title } = useHeader();

  return (
    <AppBar
      sx={[
        styles.container,
        isCollapse && styles.containerCollapsed,
        isOffset && styles.containerOffset,
        verticalLayout && styles.containerVertical,
      ]}
    >
      <Toolbar sx={styles.toolbar}>
        {!isDesktop && (
          <IconButtonAnimate onClick={openSidebarMobile} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <Box sx={styles.title}>
          {title && (
            <Typography variant="h4" color="primary">
              {title}
            </Typography>
          )}
        </Box>
        <Box sx={styles.rightSide}>
          {currentUser && <NotificationBell />}
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
