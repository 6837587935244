import { List, Box, ListSubheader, BoxProps } from '@mui/material';
import NavList from './NavList';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import styles from './NavSectionVertical.style';
import { MenuType } from 'src/view/menus';

interface Props extends BoxProps {
  isCollapse?: boolean;
  navConfig: MenuType[];
}

const NavSectionVertical: React.FC<Props> = (props) => {
  const { navConfig, isCollapse = false, ...other } = props;
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const permissionChecker = new PermissionChecker(currentUser);
  const match = (permission) => permissionChecker.match(permission);

  return (
    <Box {...other}>
      {navConfig?.map((group) => {
        const items = group?.items?.filter((menu) => match(menu.permissionRequired));

        if (items?.length === 0) {
          return null;
        }

        return (
          <List key={group.subheader} disablePadding sx={styles.list}>
            <ListSubheader
              disableSticky
              disableGutters
              sx={[styles.listSubheader, isCollapse && styles.listSubheaderCollapsed]}
            >
              {group.subheader}
            </ListSubheader>

            {items.map((item) => (
              <NavList key={item.label} item={item} isCollapse={isCollapse} />
            ))}
          </List>
        );
      })}
    </Box>
  );
};

export default NavSectionVertical;
