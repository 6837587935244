import NotificationService from 'src/modules/notification/notificationService';
import selectors from 'src/modules/notification/list/notificationListSelectors';
import Errors from 'src/modules/shared/error/errors';
// import chatConversationsSelectors from 'src/modules/chat/conversations/chatConversationsSelectors';
import chatCurrentConversationSelectors from 'src/modules/chat/currentConversation/chatCurrentConversationSelectors';
// import chatConversationsActions from 'src/modules/chat/conversations/chatConversationsActions';

const prefix = 'NOTIFICATION_LIST';

const notificationListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  REMOVE_NOTIFICATION: `${prefix}_REMOVE_NOTIFICATION`,

  doMarkAllAsViewed: () => async (dispatch, getState) => {
    NotificationService.markConversationsAsRead();
    dispatch({
      type: notificationListActions.FETCH_SUCCESS,
      payload: {
        rows: [],
        count: 0,
      },
    });
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: notificationListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(notificationListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(notificationListActions.doFetch({ filter, rawFilter, keepPagination: true }));
  },

  doFetch: (props?) => async (dispatch, getState) => {
    const { filter, rawFilter, keepPagination = false } = props ?? {};
    let newFilter = { ...filter };
    try {
      dispatch({
        type: notificationListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      // const courseId = chatConversationsSelectors.selectCourseId(getState());
      const conversationId = chatCurrentConversationSelectors.selectActiveConversationId(getState());

      // if (courseId && courseId !== '') {
      //   newFilter = { ...newFilter, courseId };
      // }
      if (conversationId && conversationId !== '') {
        const conversation = chatCurrentConversationSelectors.selectConversation(getState());
        const lastMessage = conversation?.messages?.[conversation?.messages?.length - 1];

        newFilter = {
          ...newFilter,
          conversationId,
          lastMessageId: lastMessage?.id,
          lastMessageCreatedAt: lastMessage?.createdAt,
        };
      }

      const { notifications, ...rest } = await NotificationService.list(
        newFilter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );

      // if (rest.conversations) {
      //   dispatch(chatConversationsActions.doUpdateConversations(rest.conversations));
      // }
      // if (rest.conversation) {
      //   dispatch(chatCurrentConversationAction.doUpdateCurrentConversation(rest.conversation, conversationId));
      // }

      dispatch({
        type: notificationListActions.FETCH_SUCCESS,
        payload: {
          rows: notifications.rows,
          count: notifications.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: notificationListActions.FETCH_ERROR,
      });
    }
  },

  markConversationAsRead: (id) => async (dispatch, getState) => {
    if (id && id?.length > 0) {
      dispatch({
        type: notificationListActions.REMOVE_NOTIFICATION,
        payload: id,
      });
    }
  },
};

export default notificationListActions;
