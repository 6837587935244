import CourseStudentService from 'src/modules/courseStudent/courseStudentService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'COURSE_STUDENT_EVENT_VIEW';

const courseEventViewActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,
  SET_DATA: `${prefix}_SET_DATA`,

  doInit: (courseId, moduleId, eventId) => async (dispatch) => {
    try {
      dispatch({
        type: courseEventViewActions.INIT_STARTED,
      });

      const data = await CourseStudentService.findEventDetail(courseId, moduleId, eventId);

      dispatch({
        type: courseEventViewActions.INIT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: courseEventViewActions.INIT_ERROR,
      });
    }
  },

  setData: (course, module, event) => async (dispatch) => {
    dispatch({
      type: courseEventViewActions.SET_DATA,
      payload: { course, module, event },
    });
  },
};

export default courseEventViewActions;
