import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { List, Collapse } from '@mui/material';
import NavItem from './NavItem';
import { getActive } from 'src/view/shared/nav-section';
import { MenuItemType } from 'src/view/menus';
import NavListSub from './NavListSub';

type Props = {
  item: MenuItemType;
  isCollapse: boolean;
};

const NavList: React.FC<Props> = (props) => {
  const { item, isCollapse } = props;
  const { pathname } = useLocation();
  const childActive = item?.children?.some((child) => getActive(child.path ?? '', pathname));
  const active = getActive(item?.path ?? '', pathname) || childActive;
  const [open, setOpen] = useState(active || childActive);
  const hasChildren = item?.children;

  if (hasChildren) {
    return (
      <>
        <NavItem item={item} isCollapse={isCollapse} active={active} open={open} onOpen={() => setOpen(!open)} />
        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(item.children || []).map((item) => (
                <NavListSub key={item.label} list={item} />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItem item={item} active={active} isCollapse={isCollapse} />;
};

export default NavList;
