import { ComponentStyle } from 'src/@types/Style';
import { DRAWER_ITEM_SUB_HEIGHT } from 'src/config';
import { pxToRem } from 'src/utils/getFontValue';

const styles: ComponentStyle = {
  listItemStyle: {
    lineHeight: 26 / 14,
    fontSize: pxToRem(14),
    position: 'relative',
    textTransform: 'uppercase',
    color: (theme) => theme.palette.primary.main,
    borderRadius: (theme) => theme.shape.borderRadius,
    minHeight: DRAWER_ITEM_SUB_HEIGHT,
  },
};

export default styles;
