import { ComponentStyle, IconifyStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 1,
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  listItemIcon: {
    width: 32,
    height: 32,
    p: '10px',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      color: (theme) => theme.palette.primary.main,
    },
    mr: 0,
  },
  listItemIconActive: {
    backgroundColor: (theme) => theme.palette.secondary.main,
  },
  listItemText: {
    lineHeight: 1.2,
    textTransform: 'uppercase',
    whiteSpace: 'inherit',
    transition: (theme) =>
      theme.transitions.create(['width', 'opacity'], {
        duration: theme.transitions.duration.shorter,
      }),
  },
  listItemTextActive: {
    fontWeight: 700,
  },
  listItemTextCollapsed: {
    width: 0,
    opacity: 0,
  },
};

export const iconStyle: IconifyStyle = {
  icon: {
    width: 16,
    height: 16,
    ml: 'auto',
  },
};

export default styles;
