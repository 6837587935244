import { Box, Typography, SxProps } from '@mui/material';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import { i18n } from 'src/i18n';
import styles from './KnowmaderCta.styles';
import SignButtons from 'src/view/shared/SignButtons/SignButtons';
import KnowmadersLogo from 'src/view/shared/logos/KnowmadersLogo';

interface Props {
  sx?: SxProps;
  hideButtons?: boolean;
}

const KnowmaderCta: React.FC<Props> = (props) => {
  const { sx = {}, hideButtons = false } = props;
  const currentUser = useSelector(authSelectors.selectCurrentUser);

  return (
    <Box sx={styles.componentWrapper}>
      <Box sx={styles.logoWrapper}>
        <KnowmadersLogo sx={sx} />
      </Box>
      <Box sx={styles.textWrapper}>
        <Typography sx={styles.title} variant="h5">
          {i18n('components.footer.ctaTitle')}
        </Typography>
        <Typography sx={styles.subtitle} variant="h6">
          {i18n('components.footer.slogan')}
        </Typography>
      </Box>
      {!currentUser && !hideButtons && <SignButtons />}
    </Box>
  );
};

export default KnowmaderCta;
