import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  container: {
    height: 1,
    '& .simplebar-content': {
      height: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    overflowX: 'clip',
  },
  stack: {
    pt: 3,
    pb: 2,
    px: 2.5,
    flexShrink: 0,
  },
  stackCollapsed: {
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
};

export default styles;
