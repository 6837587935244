import Iconify from 'src/view/shared/Iconify';
import { ListItemText } from '@mui/material';
import styles, { iconifyStyles } from './NavItemSubContent.style';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export type Props = {
  label: string;
  open: boolean;
  active: boolean;
};

const NavItemSubContent: React.FC<Props> = (props) => {
  const { label, open, active, children } = props;

  return (
    <>
      {active ? (
        <RadioButtonCheckedIcon sx={styles.mainIconActive} />
      ) : (
        <RadioButtonUncheckedIcon sx={[styles.mainIcon]} />
      )}

      <ListItemText disableTypography primary={label} />
      {children && (
        <Iconify sx={iconifyStyles.icon} icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} />
      )}
    </>
  );
};

export default NavItemSubContent;
