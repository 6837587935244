import authAxios from 'src/modules/shared/axios/authAxios';

export default class UserService {
  static async edit(data) {
    const body = {
      data,
    };

    const response = await authAxios.put(`/admin/user`, body);

    return response.data;
  }

  static async destroy(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/admin/user`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/admin/user`, body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: {
        ...values,
      },
      importHash,
    };

    const response = await authAxios.post(`/admin/user/import`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/admin/user/${id}`);
    return response.data;
  }

  static async fetchUsers(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/admin/user`, {
      params,
    });

    return response.data;
  }

  static async exportUsers(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/admin/user/export`, {
      params,
    });

    return response.data;
  }

  static async fetchUserAutocomplete(query, limit, roles?: string[]) {
    const params = {
      query,
      limit,
      roles,
    };

    const response = await authAxios.get(`/admin/user/autocomplete`, {
      params,
    });
    return response.data;
  }
}
