import _get from 'lodash/get';
import { setLocale as setYupLocale } from 'yup';
import { setDefaultOptions } from 'date-fns';
import * as dateFnsLocales from 'date-fns/locale';

let currentLanguageCode = '';

const languages: {
  [key: string]: {
    id: string;
    label: string;
    flag: string;
    dateFns: any;
    dictionary: any;
  };
} = {
  es: {
    id: 'es',
    label: 'Español',
    flag: '/images/flags/Spain.png',
    dateFns: null,
    dictionary: null,
  },
};

export async function init() {
  currentLanguageCode = localStorage.getItem('language') || 'es';
  setLanguageCode(currentLanguageCode);

  if (currentLanguageCode === 'es') {
    setDefaultOptions({ locale: dateFnsLocales.es, weekStartsOn: 1 });
    await initEs();
  }
}

async function initEs() {
  const language = languages['es'];

  language.dateFns = (await import('date-fns/locale/es')).default;

  language.dictionary = (await import('src/i18n/es')).default;

  if (language.dictionary.validation) {
    setYupLocale(language.dictionary.validation);
  }

  return language;
}

export function getLanguage() {
  return languages[getLanguageCode()];
}

function format(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}
function formatComponents(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.split(/{\d}/).reduce(function merge(acc, item, index) {
      return [...acc, item, ...(args?.[index] ? [{ ...args[index], key: index }] : [])];
    }, []);
  } catch (error) {
    console.error(message, error);
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map((language) => {
    return languages[language];
  });
}

export function getLanguageCode() {
  return currentLanguageCode;
}

export function setLanguageCode(arg) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }

  localStorage.setItem('language', arg);
}

export function i18nExists(key) {
  if (!getLanguage()) {
    return false;
  }

  const message = _get(getLanguage().dictionary, key);
  return Boolean(message);
}

export function i18n(key, ...args) {
  if (!getLanguage()) {
    return key;
  }

  const message = _get(getLanguage().dictionary, key);

  if (!message) {
    return key;
  }

  return format(message, args);
}

export function i18nComponents(key, ...args) {
  if (!getLanguage()) {
    return key;
  }

  const message = _get(getLanguage().dictionary, key);

  if (!message) {
    return key;
  }

  return formatComponents(message, args);
}

export function i18nHtml(key, ...args) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: i18n(key, ...args),
      }}
    />
  );
}
