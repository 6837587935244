import { useState, useEffect } from 'react';
import { Menu, MenuItem, ListItemText, Tooltip, IconButton, Box, Button, Typography } from '@mui/material';
import { i18n } from 'src/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { getHistory } from 'src/modules/store';
import notificationListSelectors from 'src/modules/notification/list/notificationListSelectors';
import notificationListActions from 'src/modules/notification/list/notificationListActions';
import authSelectors from 'src/modules/auth/authSelectors';
import styles from './NotificationBell.styles';
import Notifications from '@mui/icons-material/Notifications';
import { Notification, notificationModeMap } from 'src/@types/notification';

export default function NotificationBell() {
  const dispatch = useDispatch();
  const rows = useSelector(notificationListSelectors.selectRows);
  const hasRows = useSelector(notificationListSelectors.selectHasRows);
  const isSignedIn = useSelector(authSelectors.selectSignedIn);
  const [anchorEl, setAnchorEl] = useState(null);
  const notifications: Notification[] = rows.slice(0, 3);

  useEffect(() => {
    if (isSignedIn) {
      const fn = () => dispatch(notificationListActions.doFetch());
      fn();
    }
    // const intervalId = setInterval(fn, 30000);
    // return () => clearInterval(intervalId);
  }, [dispatch, isSignedIn]);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const doNavigateToNotification = () => getHistory().push('/notification');

  const onClickNotification = (notification) => {
    if (notification.id) {
      if (notification.mode === notificationModeMap.OneToOne) {
        getHistory().push(`/chat/${notification.id}`);
      } else {
        if (notification.isTeacher) {
          getHistory().push(`/teacher/course/${notification.courseId}/messages`);
        } else {
          getHistory().push(`/student/my-courses/${notification.courseId}/messages`);
        }
      }
    }
    handleClose();
  };

  return (
    <>
      <Tooltip title={i18n('entities.notification.menu')}>
        <IconButton sx={styles.icon} onClick={handleClick}>
          <Notifications />
          {hasRows ? <Box sx={styles.newNotifications} /> : null}
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: styles.paper,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!hasRows && (
          <MenuItem>
            <Typography variant="body2" sx={{ whiteSpace: 'break-spaces' }}>
              {i18n('entities.notification.noData')}
            </Typography>
          </MenuItem>
        )}
        {hasRows &&
          notifications.map((row) => (
            <MenuItem key={row.id} sx={styles.menuItem} onClick={() => onClickNotification(row)}>
              {row.mode === 'OneToOne' ? (
                <Typography sx={{ whiteSpace: 'break-spaces' }}>
                  {i18n(
                    'entities.notification.item',
                    row?.count === 1
                      ? i18n('entities.notification.message')
                      : i18n('entities.notification.messages', row?.count),
                    row?.creator,
                  )}
                </Typography>
              ) : null}
              {row.mode === 'OneToMany' ? (
                <Typography sx={{ whiteSpace: 'break-spaces' }}>
                  {i18n(
                    'entities.notification.itemGroup',
                    row?.count === 1
                      ? i18n('entities.notification.message')
                      : i18n('entities.notification.messages', row?.count),
                    row?.courseName,
                  )}
                </Typography>
              ) : null}
            </MenuItem>
          ))}
        <Box sx={styles.buttonMore}>
          <Button variant="contained" fullWidth onClick={doNavigateToNotification}>
            <ListItemText>{i18n('entities.notification.seeMore')}</ListItemText>
          </Button>
        </Box>
      </Menu>
    </>
  );
}
