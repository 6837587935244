import { createSelector } from 'reselect';

const selectRaw = (state) => state.category.map;

const selectMap = createSelector([selectRaw], (map) => map.map);
const selectCategories = createSelector([selectRaw], (map) => map.lists?.category);
const selectSubcategories = createSelector([selectRaw], (map) => map.lists?.subcategory);

const categoryMapSelectors = {
  selectMap,
  selectCategories,
  selectSubcategories,
};

export default categoryMapSelectors;
