import { Box } from '@mui/material';
import { NavItemProps } from 'src/@types/layout';
import { Style } from 'src/@types/Style';
import NavItemDesktop from 'src/view/layout/nav/NavItemDesktop/NavItemDesktop';
import styles from './NavDesktop.styles';

interface Props {
  isScrolling?: boolean | undefined;
  isTransparent?: boolean | undefined;
  navConfig: NavItemProps[];
  sx?: Style;
}

const NavDesktop: React.FC<Props> = (props) => {
  const { isScrolling, navConfig, sx = {} } = props;

  return (
    <Box sx={[styles.navDesktop, sx]}>
      {navConfig.map((link) => (
        <NavItemDesktop key={link.title} item={link} isScrolling={isScrolling} />
      ))}
    </Box>
  );
};

export default NavDesktop;
