import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  wrapper: {
    py: 4,
    textAlign: 'center',
    width: '90vw',
    flexDirection: { xs: 'column', md: 'row', lg: 'row', xl: 'row' },
    justifyContent: 'space-between',
    alignItems: 'center',
    rowGap: 3,
  },
  wrapperLinks: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 3,
    justifyContent: 'center',
  },
  font: {
    fontFamily: 'FF Mark',
    fontWeight: 600,
  },
};

export default styles;
