import { ConversationWithLastMessage } from 'src/@types/chat';
import actions from 'src/modules/chat/conversations/chatConversationsActions';

const initialData = {
  rows: [] as ConversationWithLastMessage[],
  count: 0,
  // courseId: '',
  loading: false,
};

export default function chatConversationsReducers(state = initialData, { type, payload }) {
  // if (type === actions.RESET) {
  //   return {
  //     ...initialData,
  //   };
  // }
  // if (type === actions.SET_COURSE_ID) {
  //   return {
  //     ...state,
  //     courseId: payload.courseId,
  //   };
  // }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }
  if (type === actions.ADD_CONVERSATION) {
    return {
      ...state,
      rows: [payload.conversation, ...state.rows],
      count: state.count + 1,
    };
  }
  if (type === actions.READ_MESSAGE) {
    const newRows = state.rows.map((row) =>
      payload.id === row.id
        ? {
            ...row,
            lastMessage: { ...row.lastMessage, readers: [...(row?.lastMessage?.readers ?? []), payload.userId] },
          }
        : row,
    );

    return {
      ...state,
      rows: newRows,
    };
  }

  return state;
}
