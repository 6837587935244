import { SocialMediaProps } from 'src/@types/knowmader';

export type Contact = {
  id: string;
  name: string;
  username: string;
  avatar: string;
  address: string;
  phone: string;
  email: string;
  lastActivity: Date | string | number;
  status: string;
  position: string;
};

export type Participant = {
  id: string;
  name: string;
  username: string;
  avatar: string;
  city?: string;
  country?: string;
  position?: string;
  socialMedia?: SocialMediaProps;
};

export type Message = {
  id: string;
  content: string;
  createdAt: Date;
  createdBy: string;
  conversation: string;
  readers: string[];
};

export const conversationModeMap = {
  OneToOne: 'OneToOne',
  OneToMany: 'OneToMany',
} as const;
export const conversationModeValues = Object.freeze([...Object.values(conversationModeMap)] as const);
export type ConversationModeType = (typeof conversationModeValues)[number];

export type Conversation = {
  id: string;
  course: string;
  participants: Participant[];
  mode: ConversationModeType;
  messages: Message[];
};

export interface ConversationWithLastMessage {
  id: string;
  participants: Participant[];
  mode: ConversationModeType;
  name?: string;
  lastMessage?: Message;
}

export type SendMessage = {
  conversationId: string;
  messageId: string;
  message: string;
  contentType: 'text';
  attachments: string[];
  createdAt: Date | string | number;
  senderId: string;
};
