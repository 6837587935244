import categoryMapSelectors from 'src/modules/category/map/categoryMapSelectors';
import CoffeeAndBeersService from 'src/modules/coffeeAndBeers/coffeeAndBeersService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'COFFEE_AND_BEERS_TOTAL_SUMMARY';

const coffeeAndBeersTotalSummaryActions = {
  CLEAN: `${prefix}_CLEAN`,
  ADD_CATEGORY_PARTICIPANT: `${prefix}_ADD_CATEGORY_PARTICIPANT`,
  DELETE_CATEGORY_PARTICIPANT: `${prefix}_DELETE_CATEGORY_PARTICIPANT`,
  DELETE_CATEGORY_TABLE: `${prefix}_DELETE_CATEGORY_TABLE`,

  GET_TOTAL_SUMMARY_PENDING: `${prefix}_GET_TOTAL_SUMMARY_PENDING`,
  GET_TOTAL_SUMMARY_FULFILLED: `${prefix}_GET_TOTAL_SUMMARY_FULFILLED`,
  GET_TOTAL_SUMMARY_REJECTED: `${prefix}_GET_TOTAL_SUMMARY_REJECTED`,

  clean: () => async (dispatch) => {
    dispatch({ type: coffeeAndBeersTotalSummaryActions.CLEAN });
  },
  addCategoryParticipant:
    ({ topCategoryId, isNewTable, userId, userFullName, userAvatar }) =>
    async (dispatch) => {
      dispatch({
        type: coffeeAndBeersTotalSummaryActions.ADD_CATEGORY_PARTICIPANT,
        payload: {
          topCategoryId,
          isNewTable,
          userId,
          userFullName,
          userAvatar,
        },
      });
    },
  deleteCategoryParticipant:
    ({ topCategoryId, userId }) =>
    async (dispatch) => {
      dispatch({
        type: coffeeAndBeersTotalSummaryActions.DELETE_CATEGORY_PARTICIPANT,
        payload: {
          topCategoryId,
          userId,
        },
      });
    },
  deleteCategoryTable:
    ({ topCategoryId, userId }) =>
    async (dispatch) => {
      dispatch({
        type: coffeeAndBeersTotalSummaryActions.DELETE_CATEGORY_TABLE,
        payload: {
          topCategoryId,
          userId,
        },
      });
    },
  getTotalSummary: () => async (dispatch, getState) => {
    try {
      dispatch({ type: coffeeAndBeersTotalSummaryActions.GET_TOTAL_SUMMARY_PENDING });

      const response = await CoffeeAndBeersService.getTotalSummary();
      const categories = categoryMapSelectors.selectCategories(getState());

      dispatch({
        type: coffeeAndBeersTotalSummaryActions.GET_TOTAL_SUMMARY_FULFILLED,
        payload: {
          categoriesList: categories,
          data: response,
        },
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({ type: coffeeAndBeersTotalSummaryActions.GET_TOTAL_SUMMARY_REJECTED });
    }
  },
};

export default coffeeAndBeersTotalSummaryActions;
