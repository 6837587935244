import { Box, Stack } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import Scrollbar from 'src/view/shared/Scrollbar';
import NavSectionVertical from 'src/view/shared/nav-section/NavSectionVertical';
import NavbarDocs from 'src/view/layoutBackOffice/navbar/NavbarDocs';
import CollapseButton from 'src/view/layoutBackOffice/navbar/CollapseButton';
import styles from './NavbarContent.styles';
import KnowmadersUniversityLogo from 'src/view/shared/logos/KnowmadersUniversityLogo';
import KnowmadersUniversityDrawLogo from 'src/view/shared/logos/KnowmadersUniversityDrawLogo';
import { MenuType } from 'src/view/menus';

type Props = {
  isCollapse: boolean;
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
  menu: MenuType[];
};

const NavbarContent: React.FC<Props> = (props) => {
  const { isCollapse, onToggleCollapse, collapseClick, menu } = props;
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Box sx={styles.container}>
      <Scrollbar>
        <Stack spacing={3} sx={[styles.stack, isCollapse && styles.stackCollapsed]}>
          <Box sx={styles.flex}>
            {isDesktop && !isCollapse && (
              <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )}
            {isCollapse ? (
              <KnowmadersUniversityDrawLogo sx={styles.logoSize} />
            ) : (
              <KnowmadersUniversityLogo sx={styles.logoSize} />
            )}
          </Box>
        </Stack>
        <NavSectionVertical navConfig={menu} isCollapse={isCollapse} />
        <Box sx={{ flexGrow: 1 }} />
        {!isCollapse && <NavbarDocs />}
      </Scrollbar>
    </Box>
  );
};

export default NavbarContent;
