import { createSelector } from 'reselect';

const selectRaw = (state) => state.chat.conversations;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);
// const selectCourseId = createSelector([selectRaw], (raw) => raw.courseId);

const selectCount = createSelector([selectRaw], (raw) => raw.count);

const selectHasRows = createSelector([selectCount], (count) => count > 0);

const chatConversationsSelectors = {
  selectLoading,
  selectRows,
  // selectCourseId,
  selectCount,
  selectHasRows,
};

export default chatConversationsSelectors;
