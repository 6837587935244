import actions from 'src/modules/course/eventView/courseEventViewActions';

const initialData = {
  loading: false,
  course: null,
  module: null,
  event: null,
};

export default function courseEventViewReducers(state = initialData, { type, payload }) {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      record: null,
      loading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      course: payload.course,
      module: payload.module,
      event: payload.event,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      record: null,
      loading: false,
    };
  }

  return state;
}
