import authAxios from 'src/modules/shared/axios/authAxios';

export default class NotificationService {
  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/notification`, {
      params,
    });

    return response.data;
  }

  static async markConversationsAsRead() {
    const response = await authAxios.patch('/notification', { op: 'markAllAsRead' });

    return response.data;
  }
}
