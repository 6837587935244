import authAxios from 'src/modules/shared/axios/authAxios';

export default class InvoiceService {
  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/invoice`, {
      params,
    });

    return response.data;
  }

  static async export(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/invoice/export`, {
      params,
    });

    return response.data;
  }

  static async teacherList(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/invoice-teacher`, {
      params,
    });

    return response.data;
  }
}
