import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  navDesktop: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 3,
    ml: 6,
  },
};

export default styles;
