import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 1,
  },
  circleWrapper: {
    width: '90px',
    height: '40px',
    padding: '10px',
    borderRadius: '30px',
    display: 'flex',
    alignItems: 'center',
  },
  off: {
    backgroundColor: (theme) => theme.palette.action.disabledBackground,
    justifyContent: 'flex-start',
  },
  on: {
    backgroundColor: (theme) => theme.palette.secondary.main,
    justifyContent: 'flex-end',
  },
  circle: {
    width: '30px',
    height: '30px',
    backgroundColor: (theme) => theme.palette.primary.main,
    borderRadius: '100%',
  },
  font: {
    fontFamily: 'FF Mark',
    fontWeight: 700,
  },
};

export default styles;
