export const tableStatusMap = {
  open: 'open',
  closed: 'closed',
} as const;
export const tableStatusValues = Object.freeze([...Object.values(tableStatusMap)] as const);
export type TableStatusType = (typeof tableStatusValues)[number];

export const tableDrinkMap = {
  cold: 'cold',
  hot: 'hot',
} as const;
export const tableDrinkValues = Object.freeze([...Object.values(tableDrinkMap)] as const);
export type TableDrinkType = (typeof tableDrinkValues)[number];

export interface TableParticipantConnection {
  startDate: string;
  endDate?: string;
  drink: TableDrinkType;
}

export interface TableParticipant {
  user: {
    id: string;
    name: string;
    avatar: string | undefined;
  };
  connections: TableParticipantConnection[];
}

export interface Table {
  id: string;
  name: string;
  description: string;
  createdBy: string;
  status: TableStatusType;
  participants: TableParticipant[];
  createdAt: string;
  updatedAt: string;
  topCategory: string;
  subcategory: string;
}
