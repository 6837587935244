import CallMadeIcon from '@mui/icons-material/CallMade';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { PageLinks } from '../nav/NavConfig';
import { i18n } from 'src/i18n';
import CopyRight from 'src/view/layout/footer/Footer/Copyright/Copyright';
import FooterLinks from 'src/view/layout/footer/Footer/FooterLinks/FooterLinks';
import LoggedSwitch from 'src/view/layout/footer/Footer/LoggedSwitch/LoggedSwitch';
import styles from './Footer.styles';
import KnowmaderCta from 'src/view/shared/KnowmaderCta/KnowmaderCta';
import KnowmadersLogo from 'src/view/shared/logos/KnowmadersLogo';

interface Props {
  showCta?: boolean;
}

const Footer: React.FC<Props> = (props) => {
  const { showCta = false } = props;
  const lists = PageLinks.filter((list) => list.subheader !== 'Coming Soon');

  return (
    <Box sx={styles.footer}>
      {showCta && <KnowmaderCta />}
      <Grid container sx={styles.main}>
        <Grid item xs={12} md={5} sx={styles.texts}>
          <Stack spacing={5}>
            <Typography sx={styles.font} variant="h6">
              {i18n('components.footer.description')}
            </Typography>
            <Box sx={styles.container}>
              <KnowmadersLogo />
              <LoggedSwitch />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={7} sx={styles.linksWrapper}>
          <Box sx={styles.links}>
            <FooterLinks links={lists} />
            <CallMadeIcon sx={styles.arrow} fontSize="large" />
          </Box>
        </Grid>
      </Grid>
      <CopyRight />
    </Box>
  );
};

export default Footer;
