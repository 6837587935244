import { Link as MaterialLink, LinkProps } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './NavLinkDesktop.styles';

interface Props extends LinkProps {
  open?: boolean;
  active?: boolean;
  scrolling?: boolean;
  to?: string;
  component?: Link;
}

const NavLinkDesktop: React.FC<Props> = (props) => {
  const { active = false, scrolling = false, open = false, ...otherProps } = props;
  return (
    <MaterialLink
      {...otherProps}
      variant="header"
      sx={[styles.navLink, open && styles.open, active && styles.active, active && scrolling && styles.scrolling]}
    />
  );
};

export default NavLinkDesktop;
