import { ComponentStyle } from 'src/@types/Style';
import { DRAWER_ITEM_ROOT_HEIGHT } from 'src/config';
import { pxToRem } from 'src/utils/getFontValue';

const styles: ComponentStyle = {
  listItemStyle: {
    lineHeight: 26 / 14,
    fontSize: pxToRem(14),
    position: 'relative',
    minHeight: DRAWER_ITEM_ROOT_HEIGHT,
    textTransform: 'capitalize',
    px: { xs: 0, sm: 1 },
    color: (theme) => theme.palette.primary.main,
    borderRadius: (theme) => theme.shape.borderRadius,
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
  },
};

export default styles;
