import actions from 'src/modules/course/publicView/coursePublicViewActions';

type DataType = {
  loading: boolean;
  loadingInscribed: boolean;
  record: any;
};

const initialData: DataType = {
  loading: false,
  loadingInscribed: false,
  record: null,
};

export default function coursePublicViewReducers(state = initialData, { type, payload }) {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      record: null,
      loading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      record: payload,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      record: null,
      loading: false,
    };
  }

  if (type === actions.INSCRIBED_STARTED) {
    return {
      ...state,
      loadingInscribed: true,
    };
  }

  if (type === actions.INSCRIBED_SUCCESS) {
    return {
      ...state,
      record: {
        ...state.record,
        inscriptedsTotal: state?.record?.inscriptedsTotal + 1,
        meInscripted: true,
      },
      loadingInscribed: false,
    };
  }

  if (type === actions.INSCRIBED_ERROR) {
    return {
      ...state,
      loadingInscribed: false,
    };
  }

  return state;
}
