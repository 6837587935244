import PermissionChecker from 'src/modules/auth/permissionChecker';
import { Redirect, Route, useLocation } from 'react-router-dom';
import Layout from 'src/view/layoutBackOffice/Layout';
import { PermissionType } from 'src/security/permissions';
import { UserType } from 'src/@types/user';

interface Props {
  currentUser: UserType;
  permissionRequired?: PermissionType | null;
  path: string;
  component: React.FC;
  exact: boolean;
  initialCollapsed: boolean;
}

const MyLearningZoneRoute: React.FC<Props> = (props) => {
  const { component: Component, currentUser, permissionRequired, initialCollapsed, ...rest } = props;
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(currentUser);

        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/auth/login',
                state: { from: location },
              }}
            />
          );
        }

        if (!permissionChecker.isEmailVerified) {
          return <Redirect to="/auth/email-unverified" />;
        }

        if (permissionChecker.isEmptyPermissions) {
          return <Redirect to="/auth/empty-permissions" />;
        }

        if (!permissionChecker.match(permissionRequired)) {
          return <Redirect to="/403" />;
        }

        return (
          <Layout {...props} type="student" initialCollapsed={initialCollapsed}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default MyLearningZoneRoute;
