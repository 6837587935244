import CourseService from 'src/modules/course/courseService';
import selectors from 'src/modules/course/publicList/coursePublicListSelectors';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'COURSE_PUBLIC_LIST';

const coursePublicListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected() {
    return {
      type: coursePublicListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: coursePublicListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: coursePublicListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: coursePublicListActions.RESETED,
    });

    dispatch(coursePublicListActions.doFetch());
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: coursePublicListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(coursePublicListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: coursePublicListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(coursePublicListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(coursePublicListActions.doFetch(filter, rawFilter, true));
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: coursePublicListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });

        const response = await CourseService.listPublic(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: coursePublicListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: coursePublicListActions.FETCH_ERROR,
        });
      }
    },

  doFetchRecommended:
    (countResult = 2) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: coursePublicListActions.FETCH_STARTED,
          payload: {},
        });

        const response = await CourseService.listPublic(
          null,
          selectors.selectOrderBy(getState()),
          countResult,
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: coursePublicListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: coursePublicListActions.FETCH_ERROR,
        });
      }
    },
};

export default coursePublicListActions;
