import { combineReducers } from 'redux';
import list from 'src/modules/coffeeAndBeers/list/coffeeAndBeersListReducers';
import totalSummary from 'src/modules/coffeeAndBeers/totalSummary/coffeeAndBeersTotalSummaryReducers';
import summary from 'src/modules/coffeeAndBeers/summary/coffeeAndBeersSummaryReducers';

export default combineReducers({
  list,
  totalSummary,
  summary,
});
