import { ComponentStyle } from 'src/@types/Style';
import { IconifyStyle } from 'src/@types/Style';
import { DRAWER_WIDTH } from 'src/config';

export const iconStyle: IconifyStyle = {
  icon: {
    width: 16,
    height: 16,
    ml: 1,
  },
};

const styles: ComponentStyle = {
  paper: {
    width: DRAWER_WIDTH,
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logo: {
    py: 3,
    lineHeight: 0,
  },
  divider: {
    mb: 3,
  },
};

export default styles;
