import { memo } from 'react';
import { BoxProps, Link as MaterialLink } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './KnowmadersUniversityLogoSmall.style';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  onDark?: boolean;
  isSimple?: boolean;
}

function KnowmadersUniversityLogoSmall({ onDark = false, isSimple = false, sx }: LogoProps) {
  return (
    <MaterialLink
      component={Link}
      to="/"
      sx={{
        ...styles.link,
        ...sx,
      }}
    >
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 364.29 68.77">
        <g>
          <g>
            <path d="M25.12,35.62h-1.29v5.75h-2.79V27.39h2.79v5.66h1.32l4.62-5.66h3.49l-5.86,6.82l6.3,7.16h-3.61L25.12,35.62z" />
            <path d="M49.77,41.37h-2.62l-7.09-9.34l-0.01,9.34h-2.79V27.39h2.61l7.1,9.34v-9.34h2.8V41.37z" />
            <path
              d="M68.97,34.38c0,4.17-3.05,7.25-7.35,7.25c-4.3,0-7.34-3.08-7.34-7.25c0-4.17,3.03-7.25,7.34-7.25
			C65.92,27.13,68.97,30.21,68.97,34.38z M57.19,34.38c0,2.72,1.87,4.57,4.43,4.57c2.58,0,4.43-1.86,4.43-4.57s-1.86-4.57-4.43-4.57
			C59.05,29.81,57.19,31.67,57.19,34.38z"
            />
            <path
              d="M88.78,41.37H85.4l-3-9.99l-3,9.99h-3.38l-4.17-13.98h2.9l3,10.72l3.13-10.72h3.09l3.12,10.72l3-10.72h2.85
			L88.78,41.37z"
            />
            <path
              d="M112.31,41.37h-2.79V30.82l-3.45,9.21h-2.95l-3.45-9.13v10.47h-2.79V27.39h4.08l3.63,9.44l3.62-9.44h4.08
			V41.37z"
            />
            <path
              d="M130.4,41.37h-2.98l-1.22-3.25h-5.96l-1.22,3.25h-2.9l5.44-13.98h3.4L130.4,41.37z M121.2,35.55h4.04
			l-2.02-5.4L121.2,35.55z"
            />
            <path
              d="M139.96,27.39c4.37,0,7.26,2.78,7.26,6.99s-2.89,6.99-7.26,6.99h-5.76V27.39H139.96z M136.99,30v8.77h2.94
			c2.95,0,4.38-1.85,4.38-4.38c0-2.57-1.43-4.38-4.38-4.38H136.99z"
            />
            <path d="M162.18,41.37h-10.44V27.39h10.36v2.56h-7.58v3.1h7.23v2.56h-7.23v3.21h7.66V41.37z" />
            <path
              d="M172.95,27.39c3.33,0,5.12,1.58,5.12,4.25c0,2.3-1.6,3.86-4.28,4.12l4.3,5.6h-3.38l-4.07-5.56h-0.82v5.56
			h-2.79V27.39H172.95z M169.82,29.96v3.42h3.08c1.52,0,2.31-0.56,2.31-1.74c0-1.16-0.83-1.69-2.31-1.69H169.82z"
            />
            <path
              d="M192.04,30.92c-1.1-0.68-2.44-1.19-3.97-1.19c-1.57,0-2.68,0.62-2.68,1.68c0,1.03,1.21,1.34,2.89,1.62
			l0.79,0.13c2.5,0.41,4.45,1.48,4.45,3.91c0,3.09-2.72,4.55-5.72,4.55c-2.12,0-4.39-0.62-5.89-1.83l1.45-2.25
			c1.01,0.81,2.66,1.51,4.45,1.51c1.55,0,2.75-0.61,2.75-1.69c0-0.97-1.02-1.33-2.97-1.64l-0.89-0.14c-2.47-0.39-4.26-1.48-4.26-4
			c0-2.91,2.6-4.43,5.57-4.43c1.96,0,3.59,0.44,5.34,1.49L192.04,30.92z"
            />
            <path
              d="M200.35,40.3c0,0.67-0.58,1.26-1.25,1.26c-0.67,0-1.25-0.59-1.25-1.26c0-0.67,0.58-1.25,1.25-1.25
			C199.77,39.05,200.35,39.63,200.35,40.3z"
            />
            <path
              d="M216.83,35.9c0,3.79-2.57,5.66-5.76,5.66s-5.75-1.87-5.75-5.66v-8.51h1.6v8.39c0,2.93,1.77,4.24,4.15,4.24
			c2.39,0,4.15-1.32,4.15-4.24v-8.39h1.61V35.9z"
            />
            <path d="M234.39,41.37h-1.45l-8.66-11.22v11.22h-1.61V27.39h1.45l8.66,11.22V27.39h1.61V41.37z" />
            <path d="M242.03,41.37h-1.61V27.39h1.61V41.37z" />
            <path d="M254.01,41.37h-1.99l-5.61-13.98h1.71l4.91,12.38l4.92-12.38h1.66L254.01,41.37z" />
            <path d="M273.83,41.37h-9.84V27.39h9.75v1.51h-8.14v4.68h7.89v1.5h-7.89v4.78h8.23V41.37z" />
            <path
              d="M284.94,27.39c3.15,0,4.88,1.42,4.88,3.94c0,2.42-1.88,3.95-4.86,3.95h-0.23l5.02,6.08h-2l-4.92-6.08h-1.77
			v6.08h-1.61V27.39H284.94z M281.06,28.9v4.93h3.85c2.13,0,3.23-0.87,3.23-2.5c0-1.62-1.14-2.44-3.23-2.44H281.06z"
            />
            <path
              d="M304.1,30.04c-1.13-0.77-2.54-1.31-4.21-1.31c-2.1,0-3.61,0.95-3.61,2.53c0,1.3,1.05,2.01,3.23,2.27
			l1.33,0.16c2.31,0.28,4.36,1.26,4.36,3.67c0,2.77-2.52,4.2-5.53,4.2c-2.03,0-4.17-0.72-5.49-1.8l0.88-1.28
			c0.97,0.82,2.79,1.56,4.6,1.56c2.17,0,3.87-0.88,3.87-2.55c0-1.36-1.17-2.04-3.38-2.29l-1.41-0.16c-2.39-0.27-4.12-1.38-4.12-3.69
			c0-2.66,2.37-4.14,5.29-4.14c2.13,0,3.73,0.63,5.02,1.53L304.1,30.04z"
            />
            <path d="M312.02,41.37h-1.61V27.39h1.61V41.37z" />
            <path d="M327.74,28.91h-4.85v12.46h-1.6V28.91h-4.85v-1.52h11.3V28.91z" />
            <path d="M337.75,35.51v5.86h-1.6v-5.88l-5.53-8.1h1.92l4.42,6.63l4.42-6.63h1.87L337.75,35.51z" />
          </g>
        </g>
      </svg>
    </MaterialLink>
  );
}

export default memo(KnowmadersUniversityLogoSmall);
