import { ConnectedRouter } from 'connected-react-router';
import { configureStore, getHistory } from 'src/modules/store';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import 'typeface-roboto';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Message from 'src/view/shared/message';
import { CssBaseline, Theme } from '@mui/material';
import '@mui/styles';
import ThemeProvider from 'src/view/theme';
import { ProgressBarStyle } from 'src/view/shared/ProgressBar';
import { MotionLazyContainer } from 'src/view/shared/animate';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-alice-carousel/lib/alice-carousel.css';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const store = configureStore();

const queryClient = new QueryClient();

export default function App(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AppWithRedux {...props} />
      </Provider>
    </QueryClientProvider>
  );
}

function AppWithRedux(props) {
  return (
    <ThemeProvider>
      <SnackbarProvider maxSnack={3}>
        <MotionLazyContainer>
          <ProgressBarStyle />
          <CssBaseline />
          <AppWithSnackbar {...props} />
        </MotionLazyContainer>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

function AppWithSnackbar(props) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // this is a little hack to not have to import notistack
    // on all the components that emit messages
    Message.registerNotistakEnqueueSnackbar(enqueueSnackbar);
  }, [enqueueSnackbar]);

  return (
    <>
      <ConnectedRouter history={getHistory()}>
        <RoutesComponent />
      </ConnectedRouter>
    </>
  );
}
