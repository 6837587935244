import { memo } from 'react';
import { BoxProps, Link as MaterialLink } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './KnowmadersUniversityDrawLogo.style';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  onDark?: boolean;
  isSimple?: boolean;
}

function KnowmadersUniversityDrawLogo({ onDark = false, isSimple = false, sx }: LogoProps) {
  return (
    <MaterialLink
      component={Link}
      to="/"
      sx={{
        ...styles.link,
        ...sx,
      }}
    >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="94.44" height="81.4" viewBox="0 0 24.987 21.537">
        <g transform="translate(-113.39 -86.248)">
          <g transform="matrix(.26458 0 0 .26458 77.688 77.017)">
            <path d="m229.36 95.63c0-3.63-2.16-7.05-5.94-10.02 3.78-2.97 5.94-6.38 5.94-10.02 0-3.63-2.16-7.05-5.94-10.02 3.78-2.97 5.94-6.38 5.94-10.02 0-11.39-21.18-20.66-47.22-20.66s-47.22 9.27-47.22 20.66c0 3.63 2.16 7.05 5.94 10.02-3.78 2.97-5.94 6.38-5.94 10.02 0 3.63 2.16 7.05 5.94 10.02-3.78 2.97-5.94 6.38-5.94 10.02 0 11.39 21.18 20.66 47.22 20.66s47.22-9.27 47.22-20.66zm-1.24-20.04c0 3.37-2.05 6.54-5.64 9.31-6.37-4.57-16.68-7.97-28.84-9.31 12.17-1.34 22.47-4.74 28.84-9.31 3.59 2.77 5.64 5.94 5.64 9.31zm-6.61 10.02c-8.06 5.63-22.69 9.4-39.37 9.4s-31.31-3.77-39.37-9.4c8.06-5.63 22.69-9.4 39.37-9.4s31.31 3.77 39.37 9.4zm-39.36-10.64c-16.68 0-31.31-3.77-39.37-9.4 8.06-5.63 22.69-9.4 39.37-9.4s31.31 3.77 39.37 9.4c-8.07 5.63-22.7 9.4-39.37 9.4zm-45.98-19.41c0-10.7 20.62-19.41 45.98-19.41s45.98 8.71 45.98 19.41c0 3.37-2.05 6.54-5.64 9.31-8.3-5.95-23.27-9.93-40.34-9.93s-32.04 3.98-40.34 9.93c-3.59-2.77-5.64-5.94-5.64-9.31zm0 20.03c0-3.37 2.05-6.54 5.64-9.31 6.37 4.57 16.68 7.97 28.84 9.31-12.17 1.34-22.47 4.74-28.84 9.31-3.59-2.76-5.64-5.94-5.64-9.31zm45.98 39.45c-25.35 0-45.98-8.71-45.98-19.41 0-3.37 2.05-6.54 5.64-9.31 8.3 5.95 23.27 9.93 40.34 9.93s32.04-3.98 40.34-9.93c3.59 2.76 5.64 5.94 5.64 9.31-0.01 10.7-20.63 19.41-45.98 19.41z" />
          </g>
        </g>
      </svg>
    </MaterialLink>
  );
}

export default memo(KnowmadersUniversityDrawLogo);
