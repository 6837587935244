import { combineReducers } from 'redux';
import eventView from 'src/modules/course/eventView/courseEventViewReducers';
import destroy from 'src/modules/course/destroy/courseDestroyReducers';
import form from 'src/modules/course/form/courseFormReducers';
import list from 'src/modules/course/list/courseListReducers';
import publicList from 'src/modules/course/publicList/coursePublicListReducers';
import view from 'src/modules/course/view/courseViewReducers';
import publicView from 'src/modules/course/publicView/coursePublicViewReducers';

export default combineReducers({
  eventView,
  list,
  publicList,
  form,
  view,
  publicView,
  destroy,
});
