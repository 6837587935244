/**
 * List of Plans
 */

const plans = {
  free: 'free',
  growth: 'growth',
  enterprise: 'enterprise',
} as const;

export type PlansType = keyof typeof plans;
class Plans {
  static get values() {
    return plans;
  }
}

export default Plans;
