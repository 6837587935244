import { useState, useEffect } from 'react';
import chevronDown from '@iconify/icons-carbon/chevron-down';
import chevronUp from '@iconify/icons-carbon/chevron-up';
import NavDesktopMenu from '../NavDesktopMenu';
import { Link, useLocation } from 'react-router-dom';
import Iconify from 'src/view/shared/Iconify';
import NavLinkDesktop from 'src/view/layout/nav/NavLinkDesktop/NavLinkDesktop';
import styles from './NavItemDesktop.styles';
import { NavItemProps } from 'src/@types/layout';

interface Props {
  item: NavItemProps;
  isScrolling: boolean | undefined;
}

const NavItemDesktop: React.FC<Props> = (props) => {
  const {
    item: { title, path, children },
    isScrolling,
  } = props;

  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleClose();
  }, [pathname]);

  return children ? (
    <>
      <NavLinkDesktop onClick={handleOpen} open={open} scrolling={isScrolling}>
        {title}
        <Iconify icon={open ? chevronUp : chevronDown} sx={styles.icon} />
      </NavLinkDesktop>
      <NavDesktopMenu lists={children} isOpen={open} onClose={handleClose} isScrolling={isScrolling} />
    </>
  ) : (
    <NavLinkDesktop key={title} component={Link} to={path} active={path === pathname} scrolling={isScrolling}>
      {title}
    </NavLinkDesktop>
  );
};

export default NavItemDesktop;
