import Masonry from '@mui/lab/Masonry';
import { Link as MaterialLink, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import footerLinksStyles from './FooterLinks.styles';

interface Props {
  links: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
}

const FooterLinks: React.FC<Props> = (props) => {
  const { links } = props;
  return (
    <Masonry columns={3} spacing={3}>
      {links.map(({ subheader, items }) => (
        <Stack key={subheader} sx={footerLinksStyles.column}>
          <Typography variant="h5" sx={footerLinksStyles.linkHeader}>
            {subheader}
          </Typography>
          {items?.map((link) => (
            <MaterialLink key={link.title} variant="body1" component={Link} to={link.path} sx={footerLinksStyles.link}>
              {link.title}
            </MaterialLink>
          ))}
        </Stack>
      ))}
    </Masonry>
  );
};

export default FooterLinks;
