import PermissionChecker from 'src/modules/auth/permissionChecker';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PublicRoute({ component: Component, currentUser, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(currentUser);

        if (permissionChecker.isAuthenticated) {
          if (props?.location?.state?.from) {
            return <Redirect to={props?.location?.state?.from} />;
          } else {
            return <Redirect to="/" />;
          }
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PublicRoute;
