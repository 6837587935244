import authAxios from 'src/modules/shared/axios/authAxios';

export default class SettingsService {
  static async edit(data) {
    const body = {
      data,
    };

    const response = await authAxios.put(`/admin/settings`, body);

    return response.data;
  }

  static async find() {
    const response = await authAxios.get(`/admin/settings`);
    return response.data;
  }
}
