import { memo } from 'react';
import { BoxProps, Link as MaterialLink } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './KnowmadersUniversityLogo.style';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  onDark?: boolean;
  isSimple?: boolean;
}

function KnowmadersUniversityLogo({ onDark = false, isSimple = false, sx }: LogoProps) {
  return (
    <MaterialLink
      component={Link}
      to="/"
      sx={{
        ...styles.link,
        ...sx,
      }}
    >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 364.29 191.9">
        <g>
          <path
            className="st0"
            d="M30.8,153.87c0.33-0.43,0.62-0.96,0.85-1.57l1.89-4.92h-2.16l-1.57,4.14c-0.3,0.77-0.69,1.32-1.16,1.64
		c-0.47,0.32-1.2,0.48-2.17,0.48h-1.79v-6.26h-2.04v14.75h2.04v-6.5h1.79c0.58,0,1.11-0.05,1.57-0.14l3.84,6.64h2.35l-4.32-7.41
		C30.25,154.48,30.55,154.19,30.8,153.87z"
          />
          <polygon
            className="st0"
            points="48.59,158.33 40.75,147.37 38.99,147.37 38.99,162.12 41.01,162.12 41.01,151.15 48.85,162.12 
		50.61,162.12 50.61,147.37 48.59,147.37 	"
          />
          <path
            className="st0"
            d="M67.62,148.08c-1.14-0.64-2.47-0.97-3.94-0.97c-1.46,0-2.78,0.33-3.92,0.97c-1.15,0.65-2.06,1.56-2.71,2.72
		c-0.64,1.15-0.97,2.48-0.97,3.95c0,1.47,0.33,2.8,0.97,3.95c0.65,1.16,1.56,2.07,2.71,2.72c1.14,0.64,2.46,0.97,3.92,0.97
		c1.47,0,2.8-0.33,3.94-0.97c1.15-0.65,2.06-1.56,2.7-2.72c0.63-1.15,0.96-2.48,0.96-3.95c0-1.47-0.32-2.8-0.96-3.95
		C69.68,149.64,68.78,148.73,67.62,148.08z M68.53,157.68c-0.46,0.84-1.12,1.49-1.95,1.96c-0.84,0.47-1.81,0.7-2.9,0.7
		c-1.09,0-2.06-0.24-2.89-0.7c-0.82-0.46-1.47-1.12-1.94-1.97c-0.47-0.85-0.7-1.83-0.7-2.92c0-1.1,0.24-2.09,0.7-2.93
		c0.46-0.84,1.12-1.49,1.94-1.96c0.83-0.47,1.8-0.7,2.89-0.7c1.09,0,2.06,0.24,2.9,0.7c0.83,0.46,1.48,1.12,1.95,1.96
		c0.47,0.84,0.71,1.83,0.71,2.93C69.24,155.85,69,156.84,68.53,157.68z"
          />
          <polygon
            className="st0"
            points="90.01,158.57 85.91,147.37 84.18,147.37 80.1,158.55 76.47,147.37 74.33,147.37 79.06,161.84 
		79.15,162.12 80.9,162.12 85.04,150.82 89.21,162.12 90.93,162.12 95.78,147.37 93.63,147.37 	"
          />
          <polygon
            className="st0"
            points="112.93,162.12 114.95,162.12 114.95,147.37 113.2,147.37 107.64,155.75 102.07,147.37 100.3,147.37 
		100.3,162.12 102.34,162.12 102.34,151.38 106.92,158.25 108.35,158.25 112.93,151.38 	"
          />
          <path
            className="st0"
            d="M133.31,162.12l-6.11-14.75h-1.71l-6.11,14.75h2.19l1.43-3.5h6.68l1.45,3.5H133.31z M123.82,156.68l2.53-6.15
		l2.54,6.15H123.82z"
          />
          <path
            className="st0"
            d="M150.23,154.75c0-1.46-0.32-2.76-0.94-3.86c-0.62-1.11-1.53-1.99-2.69-2.6c-1.15-0.61-2.5-0.91-4.02-0.91
		h-4.81v14.75h4.81c1.52,0,2.87-0.31,4.02-0.91c1.16-0.61,2.07-1.49,2.69-2.6C149.92,157.5,150.23,156.2,150.23,154.75z
		 M147.49,157.61c-0.44,0.8-1.09,1.43-1.92,1.86c-0.84,0.44-1.85,0.67-3,0.67h-2.77v-10.79h2.73c1.16,0,2.18,0.22,3.02,0.67
		c0.84,0.44,1.48,1.05,1.93,1.86c0.45,0.81,0.68,1.77,0.68,2.86C148.17,155.84,147.94,156.8,147.49,157.61z"
          />
          <polygon
            className="st0"
            points="157.75,155.58 164.21,155.58 164.21,153.64 157.75,153.64 157.75,149.34 164.73,149.34 164.73,147.37 
		155.71,147.37 155.71,162.12 164.95,162.12 164.95,160.16 157.75,160.16 	"
          />
          <path
            className="st0"
            d="M180.16,155.23c0.7-0.83,1.05-1.88,1.05-3.11c0-0.95-0.21-1.79-0.63-2.5c-0.42-0.71-1.02-1.27-1.79-1.67
		c-0.75-0.39-1.63-0.58-2.63-0.58h-5.57v14.75h2.04v-5.23h2.97l3.74,5.23h2.47l-3.88-5.48
		C178.85,156.39,179.59,155.91,180.16,155.23z M172.64,154.91v-5.56h3.41c0.99,0,1.76,0.25,2.3,0.75c0.54,0.49,0.8,1.15,0.8,2.02
		c0,0.88-0.26,1.55-0.8,2.04c-0.54,0.5-1.32,0.75-2.3,0.75H172.64z"
          />
          <path
            className="st0"
            d="M195.51,161.27c0.99-0.76,1.5-1.81,1.5-3.1c0-1.13-0.43-2.07-1.28-2.79c-0.82-0.69-2.1-1.23-3.92-1.65
		c-1.37-0.3-2.34-0.67-2.89-1.09c-0.5-0.38-0.74-0.84-0.74-1.41c0-0.63,0.25-1.13,0.78-1.51c0.55-0.41,1.35-0.62,2.37-0.62
		c1.06,0,1.9,0.24,2.5,0.71c0.58,0.47,0.88,1.11,0.93,1.98l0.02,0.39h2.02l-0.02-0.43c-0.06-1.45-0.58-2.6-1.55-3.42
		c-0.96-0.8-2.27-1.21-3.9-1.21c-1.56,0-2.82,0.37-3.75,1.09c-0.96,0.75-1.44,1.77-1.44,3.02c0,1.16,0.44,2.11,1.29,2.82
		c0.82,0.68,2.1,1.21,3.9,1.63c1.33,0.29,2.29,0.65,2.86,1.07c0.51,0.38,0.76,0.85,0.76,1.45c0,0.67-0.27,1.19-0.82,1.58
		c-0.59,0.42-1.42,0.63-2.46,0.63c-1.12,0-2.03-0.26-2.69-0.77c-0.65-0.5-0.99-1.18-1.03-2.08l-0.02-0.39h-2.02l0.01,0.43
		c0.03,0.97,0.29,1.82,0.78,2.55c0.49,0.73,1.17,1.29,2.03,1.68c0.85,0.38,1.84,0.57,2.93,0.57
		C193.26,162.38,194.56,162.01,195.51,161.27z"
          />
          <path
            className="st0"
            d="M201.09,148.2v8.43c0,1.79,0.47,3.19,1.4,4.21c0.93,1.01,2.22,1.52,3.85,1.52c1.63,0,2.91-0.51,3.85-1.52
		c0.94-1.01,1.4-2.42,1.4-4.21v-8.43h-1.19v8.37c0,1.48-0.35,2.63-1.06,3.42c-0.7,0.8-1.7,1.19-3,1.19c-1.3,0-2.3-0.4-3-1.19
		c-0.7-0.8-1.06-1.94-1.06-3.42v-8.37H201.09z"
          />
          <polygon
            className="st0"
            points="229.49,148.2 228.29,148.2 228.29,160.04 219.82,148.2 218.69,148.2 218.69,162.12 219.88,162.12 
		219.88,150.27 228.35,162.12 229.49,162.12 	"
          />
          <rect x="236.77" y="148.2" className="st0" width="1.21" height="13.92" />
          <polygon
            className="st0"
            points="251.7,155.16 249.61,160.45 244.74,148.2 243.45,148.2 249.04,162.12 250.19,162.12 255.76,148.2 
		254.49,148.2 	"
          />
          <polygon
            className="st0"
            points="262.44,155.58 268.91,155.58 268.91,154.47 262.44,154.47 262.44,149.34 269.42,149.34 269.42,148.2 
		261.23,148.2 261.23,162.12 269.64,162.12 269.64,160.99 262.44,160.99 	"
          />
          <path
            className="st0"
            d="M284.96,155.38c0.63-0.76,0.95-1.7,0.95-2.84c0-0.87-0.19-1.64-0.58-2.29c-0.39-0.65-0.92-1.15-1.62-1.51
		c-0.7-0.36-1.51-0.54-2.44-0.54h-5.15v13.92h1.21v-5.23h3.6l3.74,5.23h1.45l-3.78-5.33C283.45,156.61,284.32,156.14,284.96,155.38z
		 M277.34,155.74v-6.38h3.82c1.1,0,1.96,0.29,2.59,0.86c0.62,0.57,0.94,1.35,0.94,2.33c0,0.99-0.31,1.78-0.94,2.35
		c-0.62,0.57-1.49,0.86-2.59,0.86H277.34z"
          />
          <path
            className="st0"
            d="M296.83,154.55c-1.43-0.32-2.45-0.71-3.05-1.16c-0.61-0.46-0.91-1.04-0.91-1.74c0-0.77,0.32-1.38,0.95-1.85
		c0.63-0.46,1.5-0.7,2.62-0.7c1.17,0,2.08,0.27,2.75,0.81c0.67,0.54,1.03,1.3,1.08,2.28h1.19c-0.05-1.34-0.52-2.38-1.4-3.12
		c-0.88-0.74-2.09-1.11-3.63-1.11c-1.47,0-2.63,0.34-3.49,1c-0.86,0.67-1.28,1.57-1.28,2.69c0,1.03,0.38,1.87,1.14,2.5
		c0.76,0.63,2,1.14,3.73,1.54c1.39,0.31,2.4,0.69,3.01,1.14c0.62,0.46,0.92,1.05,0.92,1.78c0,0.81-0.33,1.45-0.99,1.92
		c-0.66,0.47-1.56,0.71-2.7,0.71c-1.22,0-2.2-0.28-2.94-0.86c-0.74-0.57-1.14-1.37-1.19-2.39h-1.19c0.03,0.89,0.26,1.66,0.71,2.33
		s1.06,1.17,1.86,1.53c0.79,0.36,1.72,0.54,2.76,0.54c1.51,0,2.71-0.34,3.6-1.02c0.89-0.68,1.33-1.61,1.33-2.77
		c0-1.01-0.38-1.83-1.13-2.48C299.81,155.46,298.57,154.94,296.83,154.55z"
          />
          <rect x="308.14" y="148.2" className="st0" width="1.21" height="13.92" />
          <polygon
            className="st0"
            points="315.14,149.36 319.94,149.36 319.94,162.12 321.13,162.12 321.13,149.36 325.94,149.36 325.94,148.2 
		315.14,148.2 	"
          />
          <polygon
            className="st0"
            points="340.28,148.2 335.81,155.72 331.31,148.2 329.92,148.2 335.19,157.01 335.19,162.12 336.39,162.12 
		336.39,156.97 341.66,148.2 	"
          />
          <path
            className="st0"
            d="M229.36,90.25c0-3.63-2.16-7.05-5.94-10.02c3.78-2.97,5.94-6.38,5.94-10.02s-2.16-7.05-5.94-10.02
		c3.78-2.97,5.94-6.38,5.94-10.02c0-11.39-21.18-20.66-47.22-20.66s-47.22,9.27-47.22,20.66c0,3.63,2.16,7.05,5.94,10.02
		c-3.78,2.97-5.94,6.38-5.94,10.02s2.16,7.05,5.94,10.02c-3.78,2.97-5.94,6.38-5.94,10.02c0,11.39,21.18,20.66,47.22,20.66
		S229.36,101.64,229.36,90.25z M228.12,70.21c0,3.37-2.05,6.54-5.64,9.31c-6.37-4.57-16.68-7.97-28.84-9.31
		c12.17-1.34,22.47-4.74,28.84-9.31C226.07,63.67,228.12,66.84,228.12,70.21z M221.51,80.23c-8.06,5.63-22.69,9.4-39.37,9.4
		s-31.31-3.77-39.37-9.4c8.06-5.63,22.69-9.4,39.37-9.4S213.45,74.6,221.51,80.23z M182.15,69.59c-16.68,0-31.31-3.77-39.37-9.4
		c8.06-5.63,22.69-9.4,39.37-9.4s31.31,3.77,39.37,9.4C213.45,65.82,198.82,69.59,182.15,69.59z M136.17,50.18
		c0-10.7,20.62-19.41,45.98-19.41s45.98,8.71,45.98,19.41c0,3.37-2.05,6.54-5.64,9.31c-8.3-5.95-23.27-9.93-40.34-9.93
		s-32.04,3.98-40.34,9.93C138.22,56.72,136.17,53.55,136.17,50.18z M136.17,70.21c0-3.37,2.05-6.54,5.64-9.31
		c6.37,4.57,16.68,7.97,28.84,9.31c-12.17,1.34-22.47,4.74-28.84,9.31C138.22,76.75,136.17,73.58,136.17,70.21z M182.15,109.66
		c-25.35,0-45.98-8.71-45.98-19.41c0-3.37,2.05-6.54,5.64-9.31c8.3,5.95,23.27,9.93,40.34,9.93s32.04-3.98,40.34-9.93
		c3.59,2.76,5.64,5.94,5.64,9.31C228.12,100.95,207.5,109.66,182.15,109.66z"
          />
        </g>
      </svg>
    </MaterialLink>
  );
}

export default memo(KnowmadersUniversityLogo);
