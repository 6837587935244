import { IconifyStyle } from 'src/@types/Style';

const iconStyle: IconifyStyle = {
  icon: {
    ml: 0.5,
    width: 16,
    height: 16,
  },
};

export default iconStyle;
