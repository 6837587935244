import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  button: {
    color: (theme) => theme.palette.getContrastText(theme.palette.secondary.main),
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    minWidth: 'auto',
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  name: {
    lineHeight: 1,
    textTransform: 'uppercase',
  },

  pictureLarge: {
    width: '50px',
    height: '50px',
    borderRadius: '50px',
    border: (theme) => `3px solid ${theme.palette.primary.main}`,
  },
  pictureSmall: {
    width: '30px',
    height: '30px',
    borderRadius: '30px',
    border: (theme) => `3px solid ${theme.palette.primary.main}`,
  },
  withoutPicture: {
    color: 'primary.main',
  },
  text: {
    margin: (theme) => theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: (theme) => theme.palette.text.primary,
    ...(theme) => ({
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    }),
  },
  optionText: {
    margin: (theme) => theme.spacing(0, 0.5, 0, 1),
  },
  paper: {
    maxWidth: '400px',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    px: 2,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 25,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
  },
};

export default styles;
