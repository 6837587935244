import { AppBar } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';
import styles from './Header.styles';
import HeaderToolbar from 'src/view/layout/header/HeaderToolbar/HeaderToolbar';
import HeaderMobile from 'src/view/layout/header/HeaderMobile/HeaderMobile';
import HeaderDesktop from 'src/view/layout/header/HeaderDesktop/HeaderDesktop';

interface Props {
  transparentHeader: boolean;
}

const Header: React.FC<Props> = (props) => {
  const { transparentHeader } = props;
  const isDesktop = useResponsive('up', 'md');

  return (
    <AppBar sx={styles.app}>
      <HeaderToolbar disableGutters sx={styles.toolbar}>
        {isDesktop ? (
          <HeaderDesktop transparentHeader={transparentHeader} />
        ) : (
          <HeaderMobile transparentHeader={transparentHeader} />
        )}
      </HeaderToolbar>
    </AppBar>
  );
};

export default Header;
