import { ComponentStyle, alpha } from 'src/@types/Style';
import {
  DRAWER_COLLAPSE_WIDTH,
  DRAWER_WIDTH,
  HEADER_DASHBOARD_DESKTOP_HEIGHT,
  HEADER_DASHBOARD_MOBILE_HEIGHT,
} from 'src/config';

const styles: ComponentStyle = {
  container: {
    display: 'flex',
    minHeight: 1,
  },
  main: {
    backgroundColor: (theme) => alpha(theme.palette.background.neutral, 0.2),
    flexGrow: 1,

    pt: { xs: `${HEADER_DASHBOARD_MOBILE_HEIGHT}px`, lg: `${HEADER_DASHBOARD_DESKTOP_HEIGHT}px` },
    width: { lg: `calc(100% - ${DRAWER_WIDTH}px)` },
    transition: (theme) => ({
      lg: theme.transitions.create('margin-left', {
        duration: theme.transitions.duration.shorter,
      }),
    }),
  },
  hoverOnMainCollapsed: {
    marginLeft: { lg: `${DRAWER_COLLAPSE_WIDTH}px` },
    // width: { lg: `calc(100% - ${DRAWER_COLLAPSE_WIDTH}px)` },
  },
};

export default styles;
