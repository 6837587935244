import actions from 'src/modules/courseStudent/eventView/courseEventViewActions';

const initialData = {
  loading: false,
  event: null,
  module: null,
  course: null,
};

export default function courseEventViewReducers(state = initialData, { type, payload }) {
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      event: null,
      module: null,
      course: null,
      loading: true,
    };
  }

  if (type === actions.INIT_SUCCESS) {
    return {
      ...state,
      event: payload.event,
      module: payload.module,
      course: payload.course,
      loading: false,
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      event: null,
      module: null,
      course: null,
      loading: false,
    };
  }

  if (type === actions.SET_DATA) {
    return {
      ...state,
      event: payload.event,
      module: payload.module,
      course: payload.course,
    };
  }

  return state;
}
