import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import SettingsService from 'src/modules/settings/settingsService';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'SETTINGS_FORM';

const settingsFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: () => async (dispatch) => {
    try {
      dispatch({
        type: settingsFormActions.INIT_STARTED,
      });

      const record = await SettingsService.find();

      dispatch({
        type: settingsFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: settingsFormActions.INIT_ERROR,
      });

      getHistory().push('/back-office');
    }
  },

  doUpdate: (values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: settingsFormActions.UPDATE_STARTED,
      });

      await SettingsService.edit(values);

      dispatch({
        type: settingsFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('settings.doUpdateSuccess'));

      getHistory().push('/back-office');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: settingsFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default settingsFormActions;
