import service from 'src/modules/category/map/categoryMapService';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'CATEGORY_MAP';

const authActions = {
  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,
  doInit: () => async (dispatch) => {
    try {
      const categories = await service.getCategoriesByClassification();

      dispatch({
        type: authActions.AUTH_INIT_SUCCESS,
        payload: {
          categories,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.AUTH_INIT_ERROR,
        payload: error,
      });
    }
  },
};

export default authActions;
