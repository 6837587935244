import { Conversation } from 'src/@types/chat';
import actions from 'src/modules/chat/currentConversation/chatCurrentConversationActions';

export const statusMap = {
  new: 'new',
  conversation: 'conversation',
  default: 'default',
};

const statusValues = Object.freeze([...Object.values(statusMap)] as const);
type StatusType = (typeof statusValues)[number];

type Data = {
  activeConversationId: string;
  conversation: Conversation | undefined;
  loading: boolean;
  sendingMessage: boolean;
  status: StatusType;
};

const initialData: Data = {
  activeConversationId: '',
  conversation: undefined,
  loading: false,
  sendingMessage: false,
  status: statusMap.default,
};

export default function chatCurrentConversationReducers(state = initialData, { type, payload }) {
  if (type === actions.RESETED) {
    return {
      ...initialData,
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      conversation: payload.conversation,
      status: statusMap.conversation,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      conversation: undefined,
      status: statusMap.default,
      activeConversationId: '',
    };
  }

  if (type === actions.CREATE) {
    const conversation = {
      id: '',
      participants: [payload.contact],
      type: 'ONE_TO_ONE',
      unreadCount: 0,
      messages: [],
    };
    return {
      ...state,
      conversation: conversation,
      status: statusMap.new,
    };
  }

  if (type === actions.SEND_MESSAGE_STARTED) {
    return {
      ...state,
      sendingMessage: true,
    };
  }

  if (type === actions.SEND_MESSAGE_SUCCESS) {
    if (state.conversation) {
      const conversation = { ...state.conversation };
      conversation.messages = [...conversation.messages, payload.message];
      return {
        ...state,
        sendingMessage: false,
        conversation: conversation,
        status: statusMap.conversation,
      };
    }
  }
  if (type === actions.SEND_MESSAGE_NEW_CONVERSATION_SUCCESS) {
    return {
      ...state,
      sendingMessage: false,
      conversation: payload.conversation,
      status: statusMap.conversation,
    };
  }

  if (type === actions.SEND_MESSAGE_ERROR) {
    return {
      ...state,
      sendingMessage: false,
      conversation: undefined,
      status: statusMap.default,
    };
  }

  if (type === actions.FETCH_NEW_MESSAGES_SUCCESS) {
    if (state.conversation) {
      const conversation = { ...state.conversation };
      conversation.messages = [...conversation.messages, ...payload.newMessages];
      return {
        ...state,
        sendingMessage: false,
        conversation: conversation,
        status: statusMap.conversation,
      };
    }
  }

  if (type === actions.CHANGE_ACTIVE_ID) {
    return {
      ...state,
      activeConversationId: payload,
    };
  }

  return state;
}
