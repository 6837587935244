import authAxios from 'src/modules/shared/axios/authAxios';
import KnowmaderType from 'src/@types/knowmader';

export default class KnowmaderService {
  static async listPublic(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/public/knowmader`, {
      params,
    });

    return response.data;
  }

  static async find(id): Promise<KnowmaderType> {
    const response = await authAxios.get(`/knowmader/${id}`);

    return response.data;
  }
}
