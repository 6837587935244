import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  ctaWrapperRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 3,
  },
  ctaWrapperColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 1,
  },
  button: {
    fontWeight: 700,
    fontFamily: 'FF Mark',
  },
};

export default styles;
