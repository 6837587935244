import { MeetUpProps } from 'src/@types/meetUp';
import authAxios from 'src/modules/shared/axios/authAxios';

export default class MeetUpService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/meet-up/${id}`, body);

    return response.data;
  }

  static async doUseWallet(id) {
    const body = {
      id,
      paymentType: 'meetUp',
    };

    await authAxios.post(`/payment/teacher/withwallet`, body);
  }

  static async patch(id, op, value?) {
    const response = await authAxios.patch(`/meet-up/${id}`, { op, value });

    return response.data;
  }

  static async getDefaultConfiguration() {
    const response = await authAxios.get(`/meet-up/configuration`);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/meet-up`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/meet-up`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get<MeetUpProps>(`/meet-up/${id}`);

    return response.data;
  }

  static async findPublic(id) {
    const response = await authAxios.get(`/public/meet-up/${id}`);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/meet-up`, {
      params,
    });

    return response.data;
  }

  static async export(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/meet-up/export`, {
      params,
    });

    return response.data;
  }

  static async listPublic(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/public/meet-up`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(`/meet-up/autocomplete`, {
      params,
    });

    return response.data;
  }

  static async studentSignUp(id) {
    const response = await authAxios.post(`/meet-up/${id}/students`);

    return response.data;
  }
}
