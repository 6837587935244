export const categoryClassificationMap = {
  category: 'category',
  subcategory: 'subcategory',
  knowledgeArea: 'knowledge-area',
} as const;
export const categoryClassificationValues = Object.freeze([...Object.values(categoryClassificationMap)] as const);
export type ClassificationCategoryType = typeof categoryClassificationValues[number];

interface CategoryBaseType {
  id: string;
  name: string;
  classification: ClassificationCategoryType;
  image?: {
    downloadUrl: string;
  };
  createdAt: Date;
  updatedAt: Date;
}

interface CategoryClassificationType extends CategoryBaseType {
  classification: 'category';
  color: string;
}

interface SubCategoryClassificationType extends CategoryBaseType {
  classification: 'subcategory';
  parent: {
    name: string;
    id: string;
  };
}
interface KnowledgeAreaClassificationType extends CategoryBaseType {
  classification: 'knowledge-area';
  parent: {
    name: string;
    id: string;
    parent: {
      name: string;
      id: string;
    };
  };
}

export type CategoryType = CategoryClassificationType | SubCategoryClassificationType | KnowledgeAreaClassificationType;
