import { useState } from 'react';
import chevronRight from '@iconify/icons-carbon/chevron-right';
import chevronDown from '@iconify/icons-carbon/chevron-down';
import { Box, Collapse, ListItemText } from '@mui/material';
import Iconify from 'src/view/shared/Iconify';
import { Link, useLocation } from 'react-router-dom';
import NavSection from 'src/view/shared/NavSection';
import NavLinkMobile from 'src/view/layout/nav/NavLinkMobile/NavLinkMobile';
import { NavItemProps } from 'src/@types/layout';
import style, { iconStyle } from './NavItemMobile.styles';

interface Props {
  item: NavItemProps;
}

const NavItemMobile: React.FC<Props> = (props) => {
  const {
    item: { title, path, children },
  } = props;

  const location = useLocation();
  const pathname = location.pathname;
  const rootPath = pathname.split('/')[1];
  const isActiveRoot = pathname === path;
  const isActiveRootWithChild = pathname.includes(`/${rootPath}/`);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  return children ? (
    <>
      <NavLinkMobile onClick={handleOpen} active={isActiveRootWithChild}>
        <ListItemText disableTypography primary={title} />
        <Iconify icon={open ? chevronDown : chevronRight} sx={iconStyle.icon} />
      </NavLinkMobile>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={style.wrapper}>
          <NavSection navConfig={children} sx={style.navSection} />
        </Box>
      </Collapse>
    </>
  ) : (
    <NavLinkMobile key={title} component={Link} active={isActiveRoot} to={path}>
      <ListItemText disableTypography primary={title} />
    </NavLinkMobile>
  );
};

export default NavItemMobile;
