import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu, MenuItem, Avatar, Box, Typography } from '@mui/material';
import authSelectors from 'src/modules/auth/authSelectors';
import userSelectors from 'src/modules/user/userSelectors';
import { getHistory } from 'src/modules/store';
import authActions from 'src/modules/auth/authActions';
import { i18n } from 'src/i18n';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockIcon from '@mui/icons-material/Lock';
import { userRolesMap } from 'src/@types/user';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Divider from '@mui/material/Divider';
import SchoolIcon from '@mui/icons-material/School';
import styles from './UserMenu.styles';
import ForumIcon from '@mui/icons-material/Forum';

interface Props {
  showName?: boolean;
  size?: 'small' | 'large';
}

const UserMenu: React.FC<Props> = (props) => {
  const { showName = true, size = 'large' } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const userAvatar = useSelector(authSelectors.selectCurrentUserAvatar);
  const user = useSelector(authSelectors.selectCurrentUser);
  const userRoles = useSelector(authSelectors.selectRoles);
  const userId = useSelector(authSelectors.selectCurrentUserId);
  const hasPermissionToBackOffice = useSelector(userSelectors.selectPermissionToBackOffice);
  const hasPermissionToTeacherZone = useSelector(userSelectors.selectPermissionToTeacherZone);
  const isTeacher = userRoles.includes(userRolesMap.teacher);
  const profileUrl = `/${isTeacher ? 'teacher' : 'knowmader'}/${userId}`;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doSignOut = () => {
    dispatch(authActions.doSignOut());
  };

  const doNavigateToTeacherZone = () => {
    getHistory().push('/teacher-zone');
  };

  const doNavigateToProfile = () => {
    getHistory().push(profileUrl);
  };

  const doNavigateToChat = () => {
    getHistory().push('/chat');
  };

  const doNavigateToPasswordChange = () => {
    getHistory().push('/password-change');
  };

  const doNavigateToAdministration = () => {
    getHistory().push('/back-office');
  };

  const doNavigateToBecomeTeacher = () => {
    getHistory().push('/become-a-teacher');
  };

  return (
    <>
      <Button sx={styles.button} onClick={handleClick}>
        {showName && (
          <Box sx={styles.nameWrapper}>
            <Typography variant="body2" sx={styles.name}>
              {user.firstName}
            </Typography>
            <Typography variant="body2" sx={styles.name}>
              {user.lastName}
            </Typography>
          </Box>
        )}
        {userAvatar ? (
          <Avatar src={userAvatar} alt="avatar" sx={size === 'large' ? styles.pictureLarge : styles.pictureSmall} />
        ) : (
          <AccountCircleIcon sx={styles.withoutPicture} />
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: styles.paper,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {hasPermissionToBackOffice && (
          <MenuItem onClick={doNavigateToAdministration}>
            <EngineeringIcon />
            <Typography variant="body2" sx={styles.optionText}>
              {i18n('navigation.backOfficeZone')}
            </Typography>
          </MenuItem>
        )}
        {hasPermissionToTeacherZone && (
          <MenuItem onClick={doNavigateToTeacherZone}>
            <SchoolIcon />
            <Typography variant="body2" sx={styles.optionText}>
              {i18n('navigation.teacherZone')}
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={doNavigateToChat}>
          <ForumIcon />
          <Typography variant="body2" sx={styles.optionText}>
            {i18n('navigation.chat')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={doNavigateToProfile}>
          <PersonOutlineIcon />
          <Typography variant="body2" sx={styles.optionText}>
            {i18n('auth.profile.title')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={doNavigateToPasswordChange}>
          <LockIcon />
          <Typography variant="body2" sx={styles.optionText}>
            {i18n('auth.passwordChange.title')}
          </Typography>
        </MenuItem>

        {!isTeacher && (
          <MenuItem onClick={doNavigateToBecomeTeacher}>
            <Button variant="contained" fullWidth startIcon={<SchoolIcon />}>
              {i18n('navigation.becomeTeacher')}
            </Button>
          </MenuItem>
        )}
        <Divider orientation="horizontal" />
        <MenuItem onClick={doSignOut}>
          <ExitToAppIcon />
          <Typography variant="body2" sx={styles.optionText}>
            {i18n('auth.signOut')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
