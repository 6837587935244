import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { List, Collapse } from '@mui/material';
import NavItemSub from './NavItemSub';
import { getActive } from 'src/view/shared/nav-section';
import { MenuItemType } from 'src/view/menus';

type Props = {
  list: MenuItemType;
};

const NavListSub: React.FC<Props> = (props) => {
  const { list } = props;
  const { pathname } = useLocation();
  const active = getActive(list.path ?? '', pathname);
  const [open, setOpen] = useState(active);
  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub key={item.label} item={item} active={getActive(item.path ?? '', pathname)} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} />;
};

export default NavListSub;
