import Roles from 'src/security/roles';

export const userRolesMap = {
  ...Roles.values,
} as const;
export const userRolesValues = Object.freeze([...Object.values(userRolesMap)] as const);
export type UserRolesType = typeof userRolesValues[number];

export interface UserType {
  id: string;
  fullName: string;
  avatar?: string;
}
