import FileSaver from 'file-saver';

export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const EXCEL_EXTENSION = '.xlsx';

export class Excel {
  static saveBlobAsExcelFile(buffer, fileName) {
    FileSaver.saveAs(buffer, fileName + EXCEL_EXTENSION);
  }
}
