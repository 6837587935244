import { ComponentStyle } from 'src/@types/Style';

const styles: ComponentStyle = {
  app: {
    boxShadow: 0,
    bgcolor: 'transparent',
  },
  toolbar: {
    boxShadow: 0,
    bgcolor: 'transparent',
    alignItems: 'stretch',
  },
};

export default styles;
