import { NavLink as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { isExternalLink } from 'src/view/shared/nav-section';
import { MenuItemType } from 'src/view/menus';
import { ListItemButton } from '@mui/material';
import NavItemContent from './NavItemContent';
import styles from './NavItem.style';

type Props = {
  item: MenuItemType;
  isCollapse?: boolean;
  active?: boolean | undefined;
  open?: boolean;
  onOpen?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
};

const NavItem: React.FC<Props> = (props) => {
  const { item, isCollapse, open = false, active, onOpen } = props;

  if (item?.children) {
    return (
      <ListItemButton sx={styles.listItemStyle} onClick={onOpen}>
        <NavItemContent
          label={item.label}
          icon={item.icon}
          isCollapse={isCollapse}
          open={open}
          active={active}
          children={item?.children}
        />
      </ListItemButton>
    );
  }

  if (isExternalLink(item.path ?? '')) {
    return (
      <ListItemButton sx={styles.listItemStyle} component={Link} href={item.path} target="_blank" rel="noopener">
        <NavItemContent label={item.label} icon={item.icon} isCollapse={isCollapse} open={open} />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton sx={styles.listItemStyle} component={RouterLink} to={item.path}>
      <NavItemContent label={item.label} icon={item.icon} isCollapse={isCollapse} open={open} active={active} />
    </ListItemButton>
  );
};

export default NavItem;
