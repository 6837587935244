import { ComponentStyle } from 'src/@types/Style';
import { pxToRem } from 'src/utils/getFontValue';

const styles: ComponentStyle = {
  listSubheader: {
    fontWeight: 600,
    lineHeight: 20 / 12,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
    pl: 2,
    color: (theme) => theme.palette.text.primary,
    transition: (theme) =>
      theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
      }),
  },
  listSubheaderCollapsed: {
    opacity: 0,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
    px: 2,
  },
};

export default styles;
