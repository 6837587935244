import { NavLink as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { isExternalLink } from 'src/view/shared/nav-section';
import { MenuItemType } from 'src/view/menus';
import { ListItemButton } from '@mui/material';
import NavItemSubContent from './NavItemSubContent';
import styles from './NavItemSub.style';

export type Props = {
  item: MenuItemType;
  active?: boolean | undefined;
  open?: boolean;
  onOpen?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
};

const NavItemSub: React.FC<Props> = (props) => {
  const { item, open = false, active = false, onOpen } = props;
  const { label, path, children } = item;

  if (children) {
    return (
      <ListItemButton sx={styles.listItemStyle} onClick={onOpen}>
        <NavItemSubContent label={label} open={open} active={active} children={children} />
      </ListItemButton>
    );
  }
  if (isExternalLink(path ?? '')) {
    return (
      <ListItemButton sx={styles.listItemStyle} component={Link} href={path} target="_blank" rel="noopener">
        <NavItemSubContent label={label} open={open} active={active} />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton sx={styles.listItemStyle} component={RouterLink} to={path}>
      <NavItemSubContent label={label} open={open} active={active} />
    </ListItemButton>
  );
};

export default NavItemSub;
