import authAxios from 'src/modules/shared/axios/authAxios';

export default class ExamService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/exam/${id}`, body);

    return response.data;
  }

  static async patch(id, op, value) {
    const response = await authAxios.patch(`/exam/${id}`, { op, value });

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/exam`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/exam`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/exam/${id}`);

    return response.data;
  }

  static async findPublic(id) {
    const response = await authAxios.get(`/public/exam/${id}`);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/exam`, {
      params,
    });

    return response.data;
  }

  static async export(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/exam/export`, {
      params,
    });

    return response.data;
  }

  static async listPublic(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/public/exam`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(`/exam/autocomplete`, {
      params,
    });

    return response.data;
  }
}
