import { createSelector } from 'reselect';

const selectRaw = (state) => state.meetUp.form;

const selectRecord = createSelector([selectRaw], (raw) => raw.record);

const selectInitLoading = createSelector([selectRaw], (raw) => Boolean(raw.initLoading));

const selectSaveLoading = createSelector([selectRaw], (raw) => Boolean(raw.saveLoading));

const meetUpFormSelectors = {
  selectInitLoading,
  selectSaveLoading,
  selectRecord,
  selectRaw,
};

export default meetUpFormSelectors;
