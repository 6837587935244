import { ComponentStyle } from 'src/@types/Style';
import { IconifyStyle } from 'src/@types/Style';

export const iconStyle: IconifyStyle = {
  icon: {
    width: 16,
    height: 16,
    ml: 1,
  },
};

const styles: ComponentStyle = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  navSection: {
    position: 'relative',
    '&:before': {
      top: 0,
      bottom: 0,
      height: 0.96,
      my: 'auto',
      left: 20,
      width: '1px',
      content: "''",
      bgcolor: 'divider',
      position: 'absolute',
    },
    '& .MuiListSubheader-root': { mb: 1 },
    '& .MuiListItemButton-root': {
      backgroundColor: 'transparent',
      '&:before': { display: 'none' },
    },
    '& .MuiCollapse-root': {
      '& .MuiList-root': {
        '&:before': {
          top: 0,
          bottom: 0,
          left: 40,
          my: 'auto',
          height: 0.82,
          width: '1px',
          content: "''",
          bgcolor: 'divider',
          position: 'absolute',
        },
      },
      '& .MuiListItemButton-root': {
        pl: 8,
        '& .MuiListItemIcon-root, .MuiTouchRipple-root': {
          display: 'none',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
};

export default styles;
