import authAxios from 'src/modules/shared/axios/authAxios';

export default class TeacherTransactionService {
  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/teacher/transaction`, {
      params,
    });

    return response.data;
  }

  static async export(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/teacher/transaction/export`, {
      params,
    });

    return response.data;
  }

  static async courseTotal(courseId: string) {
    const response = await authAxios.get(`/transaction/course/${courseId}/total`);

    return response.data;
  }
}
